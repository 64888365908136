.magazin-iframe {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 300%;
    margin-bottom: 34px;

    @include desktop(){
        padding-top: 83.33%;
    }

    iframe{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: white;
    }
}
