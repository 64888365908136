.handorgel {
    $root: &;
    border: 0;
    margin: rem(50px) 0;

    .widget > & {
        margin: 0;

        & + .handorgel {
            margin-top: rem(50px);
        }
    }

    &__header {
        padding-bottom: 0;
        border: 0;
        font-size: rem(20px);
        margin-top: rem(50px);
        position: relative;

        &:first-child {
            margin-top: 0;
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: rem(40px);
            bottom: 0;
            background-color: var(--lighter-mint);
            opacity: 0;
            transition: opacity 0.2s;
        }

        &__button {
            position: relative;
            font-size: inherit;
            color: var(--not-quite-black);
            font-family: var(--gotham);
            font-weight: 700;
            line-height: normal;
            letter-spacing: 0.1em;
            margin: 0;
            background-color: var(--light-mint);
            border: 1px solid var(--light-mint);
            box-sizing: border-box;
            min-height: rem(60px);
            align-items: center;
            border-radius: rem(30px);
            padding: 0.1em rem(17px);
            text-transform: none !important;
            display: flex;
            align-items: center;

            &:before {
                @include sprite();
                width: rem(30px);
                height: rem(30px);
                content: '';
                display: block;
                background-image: var(--accordion-green);
                margin-right: rem(30px);
                transition: transform 0.2s;
                flex-shrink: 0;
            }
        }

        &--focus {
            #{$root} {
                &__header__button {
                    background-color: var(--light-mint);
                }
            }
        }

        &--open {
            #{$root} {
                &__header__button {
                    background-color: var(--light-mint);

                    &:before {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        &--opened {
            &:before {
                opacity: 1;
            }
        }
    }

    &__content {
        transition: background-color 0.2s ease, height .2s;
        border-radius: 0 0 rem(40px) rem(40px);
        border: 0;
        
        &--opened {
            background-color: var(--lighter-mint);
        }

        @media only print {
            display: block !important;
            height: auto !important;
        }

        &__inner {
            @include pr();
            border: 0;
            padding: rem(44px) rem(38px);
            overflow: hidden;
            line-height: 1.5;

            @media only print {
                opacity: 1 !important;
            }
        }
    }

    &.accordion--purple {
        #{$root} {
            &__header {
                &:before {
                    background-color: var(--even-lighter-purple);
                }

                &__button {
                    background-color: var(--another-another-lighter-purple);
                    border-color: var(--even-lighter-purple);
        
                    &:before {
                        background-image: var(--accordion-purple);
                    }
                }
        
                &--focus {
                    #{$root} {
                        &__header__button {
                            background-color: var(--another-another-lighter-purple);
                        }
                    }
                }
        
                &--open {
                    #{$root} {
                        &__header__button {
                            background-color: var(--another-another-lighter-purple);
                        }
                    }
                }
    
            }

            &__content {
                &--opened {
                    background-color: var(--even-lighter-purple);
                }
            }
        }
    }

    &.accordion--white-title {
        #{$root} {
            &__header {
                &__button {
                    background-color: white;
                    border-color: var(--dark-green);
                }
            }

            &--focus {
                #{$root} {
                    &__header__button {
                        background-color: white;
                    }
                }
            }
    
            &--open {
                #{$root} {
                    &__header__button {
                        background-color: white;
                    }
                }
            }
        }
    }


    .layout_simple{
        margin-bottom: 1em;
    }

}
