.edu-sites-container {
    display: block;
    max-width: 1120px;
    margin-left: auto;
    margin-right: auto;
}

#edu-sites {

    ul.edu-sites-nav,
    span.closeSite {
        display: none;
    }

    .edu-sites--site {
        margin-bottom: 90px;
        position: relative;

        strong {
            font-weight: bold;
            display: block;
        }

        strong.address {
            display: inline;
        }

        a,
        h3 {
            color: var(--dark-green);
        }

        h3 {
            font-size: 21px;
        }

        a {
            display: block;
            text-decoration: none;
        }

        p {
            font-size: 18px;
        }

        br {
            display: none;
        }
    }

    .inside .edu-sites--site:not(:last-child):after {
        position: absolute;
        content: '';
        display: block;
        bottom: -45px;
        height: 1px;
        width: 100%;
        background-color: #000;
        left: 0;
        right: 0;
    }

    .edu-sites-nav {
        margin-top: 0;

        li {
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }

    @include tablet() {
        background-image: url('../../images/edu-sites-map-min.png');
        background-size: 100%;
        background-position: center top;
        background-repeat: no-repeat;
        position: relative;
        width: 100%;
        padding-top: 100%;
        margin-top: 30px;

        h3 {
            font-size: 40px;
        }

        p {
            font-size: 25px;
        }

        .inside {
            position: unset;
        }

        ul.edu-sites-nav,
        span.closeSite {
            display: block;
        }

        #mobileMapHolder {
            width: 0;
            float: left;
        }

        &.open {
            background-image: url('../../images/edu-sites-map-blank-min.png');
        }

        .open {
            display: block !important;
        }

        span.closeSite {
            color: white;
            position: absolute;
            font-size: 3em;
            top: 20%;
            right: 5%;
            display: none;
            cursor: pointer;
            text-shadow: 0px 0px 9px rgba(0, 0, 0, 1);
        }

        .edu-sites {

            &--site {
                margin-bottom: unset;
                box-sizing: border-box;
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-size: 100%;
                background-position: center top;
                background-repeat: no-repeat;
                text-align: center;
                color: white;
                text-shadow: 0px 0px 9px rgba(0, 0, 0, 1);

                h3 {
                    color: white;
                    margin-top: 0;
                    margin-bottom: 0.5em;
                    font-family: var(--gotham);
                    font-size: 35px;
                }

                a {
                    text-decoration: underline;
                    color: #fff;
                    display: inline;
                }

                p {
                    font-size: 25px;
                    padding: 0;
                    margin: 0;
                    padding-bottom: 0.25em;
                }

                strong {
                    display: inline;
                }

                br {
                    display: block;
                }

                &:after {
                    display: none;
                }

                a:hover {
                    color: black;
                    text-shadow: 0px 0px 9px rgba(255, 255, 255, 1);
                }
            }

            &--tulln {
                background-image: url('../../images/edu-sites-tulln-min.png');
                padding-top: 27%;

                h3 {
                    margin-top: 1em;

                    &:first-child {
                        margin-top: 0em;
                    }
                }
            }

            &--baden {
                background-image: url('../../images/edu-sites-baden-min.png');
                padding-top: 40%;
            }

            &--langenlois {
                background-image: url('../../images/edu-sites-langenlois-min.png');
                padding-top: 34%;
            }

            &--niedersulz {
                background-image: url('../../images/edu-sites-niedersulz-min.png');
                padding-top: 40%;
            }

            &--poelten {
                background-image: url('../../images/edu-sites-poelten-min.png');
                padding-top: 45%;

                h3,
                p {
                    margin-left: -11%;
                }
            }

            &--waidhofen {
                background-image: url('../../images/edu-sites-waidhofen-min.png');
                padding-top: 35%;
            }
        }

        .edu-sites-nav {
            list-style: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 0;
            margin: 0;

            li {
                text-indent: -10000px;
                border: 1px solid transparent;
                position: absolute;
                cursor: pointer;
            }

            &--tulln {
                top: 33%;
                left: 53%;
                width: 12%;
                height: 11.5%;
            }

            &--baden {
                top: 50.6%;
                left: 61.5%;
                width: 12%;
                height: 11%;
            }

            &--langenlois {
                top: 11%;
                left: 24%;
                width: 24%;
                height: 9.5%;
            }

            &--niedersulz {
                top: 25%;
                left: 65.5%;
                width: 22.5%;
                height: 9.5%;
            }

            &--poelten {
                top: 42%;
                left: 37.5%;
                width: 12%;
                height: 12%;
            }

            &--waidhofen {
                top: 52%;
                left: 14%;
                width: 22%;
                height: 11%;
            }
        }
    }
}