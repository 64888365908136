.box-links {
    display: grid;
    grid-template-columns: 1fr;
    gap: 14px;
    margin-top: 28px;
    margin-bottom: 38px;

    @include tablet() {
        grid-template-columns: 1fr 1fr;
        margin-top: 60px;
        margin-bottom: 80px;
        column-gap: 32px;
        row-gap: 30px;
    }

    @include desktop() {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
