.news-filter {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    &:first-child:not(:last-child) {
        margin-bottom: 2.25rem;
    }

    @include tablet() {
        min-height: vwmax(430px);
    }

    & > h2 {
        @include h2-side();
    }

    .news-filter__filters {
        padding: 25px 15px;
        background-color: var(--another-light-green);
        border-radius: 0 0 20px 20px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 30px;
        
        @include tablet() {
            padding: vwmax(48px) vwmax(29px);
            border-radius: 0 0 vwmax(25px) vwmax(25px);
            gap: vwmax(50px);
        }
    }

    .news-filter__filter-section {
        height: 40px;
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;

        &:after {
            @include sprite();
            background-image: var(--arrow-right-smaller);
            width: 16px;
            height: 16px;
            position: absolute;
            right: 12px;
            top: 0;
            bottom: 0;
            margin: auto;
            content: '';
            display: block;
            transition: 0.15s;
            z-index: 2;
        }

        &.show:after {
            transform: rotate(90deg);
        }

        @include tablet() {
            height: vwmax(50px);

            &:after {
                width: vwmax(16px);
                height: vwmax(16px);
                right: vwmax(19.61px);
            }
        }
    }

    .news-filter__section-headline {
        z-index: 2;
        position: relative;
        font-family: var(--work);
        font-size: 14px;
        font-weight: 400;  
        user-select: none;

        @include tablet() {
            font-size: vwmax(18px);
        }
    }

    .news-filter__filter-elements {
        max-height: 0;
        z-index: 1;
        position: absolute;
        left: 0; top: 0; right: 0;
        padding-top: 40px;
        border-radius: 20px;
        border: 1px solid rgba(6, 7, 7, 0.2);
        background-color: white;
        overflow: hidden;

        @include tablet() {
            padding-top: vwmax(50px);
            border-radius: vwmax(25px);
        }
    }

    .news-filter__filter-section.show .news-filter__filter-elements {
        max-height: none;
    }

    .news-filter__section-headline,
    .news-filter__filter-elements {
        padding-left: 15px;
        padding-right: 15px;

        @include tablet() {
            padding-left: vwmax(31px);
            padding-right: vwmax(31px); 
        }
    }

    .news-filter__filter-section--subnav {
        &:after,
        .news-filter__section-headline {
            z-index: 6;
        }

        .news-filter__filter-elements {
            z-index: 5;
        }
    }

    .news-filter__filter-section--districts  {
        &:after,
        .news-filter__section-headline {
            z-index: 4;
        }

        .news-filter__filter-elements {
            z-index: 3;
        }
    }

    .hide-district-filter &,
    &.hide-district-filter {
        .news-filter__filter-section--districts {
            display: none;
        }
    }

    .hide-timeframe-filter &,
    &.hide-timeframe-filter {
        .news-filter__filter-section--timeframe {
            display: none;
        }
    }

    nav {
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
        }

        li > a,
        li > span,
        li > strong {
            display: block;
            color: black;
            text-decoration: none;
            font-weight: inherit;
        }

        li > span,
        li > strong {
            cursor: default;
        }

        li > a,
        li > span,
        li > strong {
            &.active,
            &.trail {
                color: black;
                text-decoration: underline;
            }
        }

        li > a {
            &:hover {
                text-decoration: underline;
            }
        }

        & > ul > li {
            margin-bottom: 1em;

            & > a,
            & > span,
            & > strong {
                font-size: 18px;
                font-family: var(--gotham);
                font-weight: 700;
                text-transform: uppercase;
            }
        }

        & > ul > li > ul {
            font-size: 15px;
            margin-left: 0.5em;
            line-height: 22px;
        }
    }

    .news-filter__filter-section--timeframe {
        .news-filter__filter-elements {
            display: flex;
            margin: 0;

            input {
                width: 50%;
                display: block;
                height: 60px;
                border: 0;
                border-radius: 0;
                box-shadow: none;
                padding: 0;
                box-sizing: border-box;

                &.news-filter__filter-section--timeframe__end {
                    text-align: right;
                }
            }
        }
    }
}
