/* All */
.navigation--main {
    @media only print {
        display: none !important;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    li {
        & > a,
        & > span,
        & > strong {
            display: block;
            text-decoration: none;
            color: inherit;
        }
    }

    ul.level_2 a:hover {
        text-decoration: underline;
    }

    /* Mobile */
    @include menu('max') {
        display: none;
        z-index: 10;
        background-color: white;
        border-radius: 12px;
        position: absolute;
        padding-bottom: 25px;
        padding-left: 14px;
        padding-right: 14px;
        top: 53px;
        left: 7px;
        right: 7px;
        max-height: calc(100vh - 91px - 40px);
        overflow-y: auto;
        box-sizing: border-box;

        body.show-menu & {
            display: block;
        }

        ul {
            & > li {
                &.submenu {
                    & > a,
                    & > span,
                    & > strong {
                        padding-right: vwmax(16px);
                        position: relative;

                        &:after {
                            pointer-events: none;
                            content: '';
                            display: block;
                            width: vw(12px);
                            position: absolute;
                            right: 0;
                            top: 0;
                            margin: auto;
                            height: 100%;
                            background-image: var(--arrow-down);
                            background-position: right center;
                            background-size: vw(12px) auto;
                            background-repeat: no-repeat;
                        }
                    }

                    &.open {
                        & > a,
                        & > span,
                        & > strong {
                            &:after {
                                transform: scaleY(-1);
                            }
                        }

                    }
                }
            }
        }

        ul.level_1 {
            margin-top: 16px;

            & > li {
                border-bottom: 1px solid black;

                & > a,
                & > span,
                & > strong {
                    font-family: var(--gotham);
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 22px;
                    padding: 11px 0;
                }
            }
        }

        ul.level_2 {
            display: none;
            padding-left: 10px;
            padding-bottom: 14px;

            & > li {
                & > a,
                & > span,
                & > strong {
                    font-family: var(--work);
                    font-size: 15px;
                    line-height: 1.166;
                    font-weight: 600;
                    padding: 4px 0;
                    border-bottom: 1px solid black;
                }

                &.submenu-text-item {
                    display: none;
                }

                & + li {
                    margin-top: 16px;
                }

                &.submenu-text-item + li {
                    margin-top: 0;
                }
            }
        }

        ul.level_1 {
            & > li.open {
                & > ul.level_2 {
                    display: block;
                }
            }
        }

        ul.level_3 {
            display: none;
            margin-top: 4px;

            & > li {
                & > a,
                & > span,
                & > strong {
                    font-family: var(--work);
                    font-size: 15px;
                    font-weight: 500;

                    &.active,
                    &.trail {
                        font-weight: 700;
                    }
                }
            }
        }

        ul.level_2 {
            & > li.open {
                & > ul.level_3 {
                    display: block;
                }
            }
        }
    }
    
    /* Desktop */
    @include menu() {
        margin-top: vwmax(14.49px);
        padding-left: vwmax(31px);
        position: relative;
        grid-area: menu;
    
        ul.level_1 {
            display: flex;
            align-items: flex-end;
            gap: vwmax(28px);

            body.scroll--sticky & {
                gap: vwmax(44px);
            }

            body.layout--international & {
                gap: vwmax(82px);
            }

            body.layout--international.scroll--sticky & {
                gap: vwmax(128px);
            }
    
            & > li {
                position: relative;
    
                & > a,
                & > span,
                & > strong {
                    position: relative;
                    color: var(--dark-green);
                    font-family: var(--gotham);
                    font-size: vwmax(20px);
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    padding-top: vwmax(19px);
                    padding-bottom: vwmax(19px);
                    cursor: pointer;
                    white-space: nowrap;

                    body.scroll--sticky & {
                        font-size: vwmax(18px);
                        padding-top: vwmax(17px);
                        padding-bottom: vwmax(17px);
                    }

                    &.page--shop {
                        background-repeat: no-repeat;
                        background-size: 100% auto;
                        background-image: var(--cart);
                        background-position: center top;
                        width: vwmax(34px);
                        height: vwmax(30px);
                        text-indent: 100px;
                        overflow: hidden;
                        padding-top: 0;
                        white-space: nowrap;
                        padding-bottom: vwmax(20px);

                        body.scroll--sticky & {
                            width: vwmax(23px);
                            height: vwmax(20px);
                            padding-top: 0;
                            padding-bottom: vwmax(20px);
                        }
                    }
                }
    
                &:hover,
                &.trail,
                &.active,
                &.open {
                    & > a,
                    & > span,
                    & > strong {
                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                            left: 0;
                            right: 0;
                            border-top: vwmax(6px) solid var(--light-green);
                            z-index: 6;
                            background-repeat: no-repeat;
                            background-position: center bottom;
                            background-size: vwmax(31px) auto;
                            height: vwmax(22px);
                            bottom: vwmax(-25px);
                        }

                        &.page--shop {
                            &:after {
                                display: none;
                            }
                        }
                    }
                }
    
                &.open {
                    & > a,
                    & > span,
                    & > strong {
                        &.submenu:after {
                            background-image: url('data:image/svg+xml;utf8,%3Csvg%20width%3D%2231%22%20height%3D%2217%22%20viewBox%3D%220%200%2031%2017%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M15.5%2017L0.344556%200.499997L30.6554%200.5L15.5%2017Z%22%20fill%3D%22%2395C11F%22/%3E%3C/svg%3E');
                        }
                    }
                }
            }
        }
    
        ul.level_2 {
            display: none;
            position: absolute;
            left: vwmax(-37px);
            width: vwmax(552px);
            background-color: white;
            padding: vwmax(37px);
            box-sizing: border-box;
            border-radius: 0px 0px 15px 15px;
            overflow-y: auto;
            max-height: calc(100vh - #{vwmax(150px)});

            body.scroll--sticky & {
                max-height: calc(100vh - #{vwmax(100px)});
            }
    
            & > li {
                border-bottom: 1px dashed black;
    
                & > a,
                & > span,
                & > strong {
                    position: relative;
                    font-family: var(--work);
                    font-size: vwmax(19px);
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: 0.03em;
                    padding: vwmax(15px) 0;

                    &.active {
                        text-decoration: underline;
                    }
                }
    
                &.submenu {
                    position: relative;
    
                    & > a,
                    & > span,
                    & > strong {
                        cursor: pointer;
                        padding-right: vwmax(32px);
                    }
    
                    &:after {
                        pointer-events: none;
                        content: '';
                        display: block;
                        width: vwmax(19.178px);
                        height: vwmax(52px);
                        position: absolute;
                        right: 0;
                        top: 0;
                        background-image: var(--arrow-down);
                        background-position: right center;
                        background-size: vwmax(19.178px) auto;
                        background-repeat: no-repeat;
                    }
    
                    &.open {
                        &:after {
                            transform: scaleY(-1);
                        }
                    }
                }
    
                &.submenu-text-item {
                    border-bottom: 0;
                    margin-bottom: vwmax(26px);
                }
            }
        }
    
        ul.level_1 {
            & > li.open {
                & > ul.level_2 {
                    display: block;
                }
            }
        }
    
        ul.level_3 {
            display: none;
            margin-left: vwmax(13px);
            margin-bottom: vwmax(14px);
            margin-right: vwmax(22px);
    
            & > li {
                & > a,
                & > span,
                & > strong {
                    font-family: var(--work);
                    font-size: vwmax(18px);
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.3888;
                    letter-spacing: 0.03em;

                    &.active {
                        text-decoration: underline;
                    }
                }
            }
        }
    
        ul.level_2 {
            & > li.open {
                & > ul.level_3 {
                    display: block;
                }
            }
        }
    
        .submenu-text {
            --text-color: var(--light-green);
            color: var(--text-color);
            border: 1px solid var(--text-color);
            border-radius: vwmax(100px);
            padding: vwmax(16px) vwmax(26px);
            font-family: var(--gotham);
            font-size: vwmax(22px);
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 0.15em;
    
            p {
                margin: 0;
            }
        }
    }
}
