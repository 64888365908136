/* hide, hide-desktop, hide-mobile */
.hide {
    display: none;
}

.hide-desktop {
    display: block;
}

.hide-mobile {
    display: none;
}

@include desktop() {
    .hide-desktop {
        display: none;
    }

    .hide-mobile {
        display: block;
    }
}
