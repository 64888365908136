.newslist--podcasts {
    margin: rem(42px) 0;

    .newslist {
        &__articles {
            display: grid;
            grid-template-columns: 1fr;
            gap: rem(34px);

            .podcast {
                min-width: 0;
                max-width: none;
            }

            @include desktop() {
                grid-template-columns: repeat(2, 1fr);
            }

            @include larger() {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }
}

.mod_newslist,
.mod_newsarchive {
    turbo-frame & {
        transition: opacity 0.2s;
    }

    turbo-frame[busy] & {
        opacity: 0.2;
    }

    .layout_latest.--new {

        figure.image_container {
            position: relative;

            &:after {
                position: absolute;
                content: 'Neu';
                text-transform: uppercase;
                background-color: #e9c427;
                display: block;
                width: 100%;
                max-width: 82px;
                font-size: 24px;
                font-weight: bold;
                line-height: 1.4;
                text-align: center;
                font-family: var(--gotham);
                right: 0px;
                bottom: 20px;
                padding-top: 3px;
                padding-bottom: 3px;
            }
        }
    }

    &.two-columns,
    &.three-columns,
    &.four-columns,
    &.related-news {
        .newslist__articles {
            display: grid;
            grid-template-columns: 1fr;
            gap: vwmax(34px);

            & > * {
                min-width: 0;
                max-width: none;
            }

            .layout_latest {
                margin-top: 0;
            }
        }
    }

    &.two-columns .newslist__articles {
        @include tablet() {
            grid-template-columns: 1fr 1fr;
        }
    }

    &.three-columns .newslist__articles,
    &.related-news .newslist__articles {
        @include tablet() {
            grid-template-columns: 1fr 1fr;
        }

        @include desktop() {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    &.four-columns .newslist__articles {
        @include tablet() {
            grid-template-columns: 1fr 1fr;
        }

        @include desktop() {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }

    .newslist__load-more-container {
        margin: rem(80px) 0;
        display: flex;
        justify-content: center;
    }

    .newslist__load-more {
        --button-background-color: white;
        --button-text-color: var(--dark-green);
        --button-border-color: var(--dark-green);
        --button-hover-background-color: var(--dark-green);
        --button-hover-text-color: white;
        --button-hover-border-color: var(--dark-green);
        @include button();

        & {
            height: rem(60px);
            font-size: rem(20px);
            letter-spacing: 0.2em;
            min-width: vwmax(518px);
            box-sizing: border-box;
        }

        @include tablet() {
            height: rem(60px);
            font-size: rem(20px);
        }
    }
}

.newslist--blog {
    margin-top: 20px;
    margin-bottom: 30px;

    @include desktop() {
        margin-top: 56px;
        margin-bottom: 70px;
    }

    .newslist {
        &__articles {
            display: grid;
            grid-template-columns: 1fr;
            gap: 30px;

            @include desktop() {
                grid-template-columns: 1fr 1fr;
                column-gap: 30px;
            }
        }
    }
}

.mod_newslist.category-news-list {
    margin: rem(50px) 0;
}

.mod_newslist.orf-show-tipp {
    margin-bottom: 2em;
}

.mod_newslist.video-tipp {
    margin-bottom: 2em;
}

.mod_newslist{
    &.schaugaerten, &.partnerbetriebe{
        figcaption{
            display: none;
        }
    }
}

/* guetesiegel */
.mod_newslist.guetesiegel {

    .layout_latest {
        text-align: center;

        & > a {
            text-decoration: none;
            transition: 0;
            display: block;
    
            &:hover {
                color: inherit;
    
                h2 {
                    color: #94c11d;
                }
            }
        }

        h2 {
            font-size: 17px;
            margin-top: 16px;
            transition: color .2s;
        }

        &>.ce_text,
        &>a>.ce_text {
            margin: 0;
            min-height: 0;
            color: var(--dark-green);
            font-size: 20px;
            font-weight: 300;
            margin-top: 0.2em;
        }

        .figure-container {
            border: 1px solid var(--dark-green);
            background-color: white;
            padding: 9px;
            margin: auto;
        }

        figure {
            position: relative;
            background-color: white;
            height: 0;
            padding-top: 112.9032258%;

            img {
                position: absolute;
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
            }
        }
    }

    .layout_latest.--new {

        figure.image_container {

            &:after {
                right: -9px;
            }
        }
    }

    &.minigrid {

        &>.layout_latest {
            margin-top: 0;
            margin-bottom: 0;
            padding-bottom: 2em;
        }
    }
}
