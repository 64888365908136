/**
 * Basic styling, basic font size etc.
 */
:root {
    --max-width: 1380px;
    --text-size-mobile: #{$text-size-mobile};
    --text-size-desktop: #{$text-size-desktop};
}

html {
    scroll-behavior: smooth;
}

body, html {
    height: 100%;
}

body { 
    overflow: hidden;
    overflow-y: scroll;
}

figure {
    display: block;
    max-width: 100%;
    margin: 0;
    box-sizing: border-box;
}

main {
    display: block;
}

img {
    border: 0;
}

video {
    max-width: 100%;
    height: auto;
}

figure img {
    width: auto;
    height: auto;
    max-width: 100%;
    display: block;
    margin: 0;
}

iframe {
    border:0;
}

*:focus-visible,
button:focus-visible,
[type="submit"]:focus-visible,
input:focus-visible ~ label {
    outline-offset: 2px;
    outline-width: 2px;
    outline-style: dashed;
    outline-color: var(--dark-green);
}

input:focus-visible {
    outline-offset: 0;
    outline-style: solid;
    outline-width: 1px;
}

input[type="checkbox"],
input[type="radio"] {
    &:focus-visible {
        outline-offset: 2px;
        outline-width: 2px;
        outline-style: dashed;
        outline-color: var(--dark-green);
    }
}

.invisible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.wow {
    visibility: hidden;
}

.inside {
    position:relative;
}

.ce_player video {
    width: 100%;
    height: auto;
}

.ce_player {
    &.sidebar-element{
        .audio_container{
            padding: 20px 0;
        }
    }
}

/* default 16:9 aspect ratio */
.ce_youtube, .ce_vimeo {
    .video-wrapper,
    .video_container {
        position: relative;
        padding-bottom:56.25%;
        height:0;
    }

    .video-wrapper > *,
    .video_container > :not(.responsive):not(.caption),
    .video_container > .responsive > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
        z-index: 6;
    }
}
