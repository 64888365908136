.mod_newsmenu {
    text-align: center;
    color: var(--almost-black);
    text-align: center;
    font-family: var(--gotham);
    font-size: rem(25px);
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0.2em;

    ul {
        list-style: none;
        margin: rem(30px) 0;
        padding: 0;
    }

    a {
        text-decoration: none;
        color: inherit;

        &:hover {
            text-decoration: none;
            color: inherit;
        }
    }
}
