.schaugarten-full {
    .ce_rsce_swiper_start {
        border-radius: rem(50px);
        overflow: hidden;

        img {
            width: 100%;
        }
    }
}
