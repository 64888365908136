#footer .mod_customnav {
    font-size: 12px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @include larger() {
        font-size: vwmax(16px);
        margin-top: 0;
        margin-bottom: 0;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        justify-content: center;

        & > li {
            display: flex;

            & > a,
            & > span,
            & > strong {
                display: block;
                text-decoration: none;
            }

            & + li {
                &:before {
                    display: block;
                    content: '\00a0/\00a0';
                }
            }
        }
    }
}
