.team-element {
    border-radius: rem(50px);
    overflow: hidden;
    background-color: var(--another-lighter-mint);
    color: var(--not-quite-black);
    font-size: rem(16px);
    font-weight: 500;
    text-align: center;
    line-height: normal;
    transition: background-color 0.2s;

    &:hover {
        background-color: var(--another-light-mint);

        .team-element__image img {
            transform: scale(1.05);
        }
    }

    a {
        text-decoration: none;
    }

    &__image {
        overflow: hidden;

        img {
            transition: transform 0.8s;
            width: 100%;
        }
    }

    &__content {
        margin-top: rem(24px);
        margin-bottom: rem(34px);
        padding: 0 1em;
    }

    &__name {
        font-size: rem(22px);
        font-family: var(--gotham);
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 0;
    }

    &__role {
        margin-top: 0;
    }
}

.rs-column > .inside > .team-element + .team-element {
    margin-top: rem(23px);
}
