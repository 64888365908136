.glightbox-clean {
    .gslide-title {
        margin: 0;
    }
}

.glightbox-nig25list {
    .gslide-media {
        position: relative;

        &:after {
            @include sprite('../../images/nig25_smaller.png');
            width: 80px;
            height: 80px;
            content: '';
            display: block;
            position: absolute;
            right: 30px;
            bottom: 40px;
            pointer-events: none;
        }
    }
}
