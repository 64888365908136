.podcast {
    background-color: white;
    border-radius: rem(30px);
    padding: rem(36px);
    display: flex;
    flex-direction: column;
    max-width: rem(516px);

    &__subheadline {
        color: var(--not-quite-black);
        font-family: var(--work);
        font-size: rem(18px);
        font-weight: 400;
        letter-spacing: 0.03em;
        margin-bottom: rem(32px);
    }

    &__headline {
        color: var(--dark-green);
        font-family: var(--gotham);
        font-size: rem(25px);
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: 0.03em;
        margin-top: 0;
        margin-bottom: rem(30px);
        min-height: rem(60px);
    }

    &__images {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: rem(28px);
    }

    &__image {
        min-width: 0;
        max-width: none;
        overflow: hidden;
        border-radius: rem(20px);
        margin-bottom: rem(20px);

        img {
            width: 100%;
        }
    }

    &__speaker {
        font-size: rem(16px);
        font-style: italic;
    }

    &__teaser {
        @include pr();
        font-size: rem(20px);
        margin-bottom: rem(40px);
    }

    &__speaker + &__teaser {
        margin-top: rem(20px);
    }

    &__links {
        margin-top: auto;
        display: grid;
        grid-template-columns: 1fr;
        column-gap: rem(2px);
        row-gap: 10px;

        @include tablet() {
            grid-template-columns: 1fr 1fr;
            row-gap: rem(18px);
        }
    }

    &__link {
        min-width: 0;
        max-width: none;
    }

    &__audio {
        display: block;
        margin-top: rem(45px);
        width: 100%;
        box-sizing: border-box;
    }
}
.podcast-index{
    .podcast__speaker{
        margin-bottom: 0.8rem;
    }
}