#footer {
    border-top: 1px solid var(--dark-green);
    padding-left: 36px;
    padding-right: 15px;

    @media only print {
        display: none !important;
    }

    & > .inside {
        @include fwbg();
        max-width: 1615px;
        margin: 0 auto;
        box-sizing: border-box;
        padding-bottom: 36px;

        &:before {
            background-image: url('../../images/background.svg');
            background-position: center center;
        }

        @include larger() {
            padding-left: vwmax(36px);
            padding-bottom: vwmax(113px);
            padding-right: vwmax(92px);
            padding-top: vwmax(24px);
            display: flex;
            justify-content: space-between;
        }
    }
}

.footer {
    &__partners {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        gap: 23px;

        @include larger() {
            margin-top: 0;
            gap: vwmax(35px);
        }
    }

    &__partner {
        &--oecert {
            img {
                height: 30px;

                @include larger() {
                    height: auto;
                }
            }
        }

        &--uz {
            img {
                height: 50px;

                @include larger() {
                    height: auto;
                }
            }
        }

        &--noe {
            img {
                height: 46px;

                @include larger() {
                    height: auto;
                }
            }
        }

        &--noe {
            img {
                height: 44px;

                @include larger() {
                    height: auto;
                }
            }
        }
    }

    &__socials {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 7px;
    }

    &__social {
        img {
            @include larger() {
                height: vwmax(33px);
            }
        }
    }
}
