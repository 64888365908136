.mod_iso_checkout {
    max-width: 1028px;

    & > h1 {
        border-bottom: 0;
        padding-bottom: 0;
        margin-bottom: rem(62px);
    }

    .submit_container {
        margin-top: rem(34px);
        display: flex;
        justify-content: flex-end;
        gap: 1em;
        flex-wrap: wrap;

        button {
            @include button();
            
            & {
                min-width: 240px;
            }
        }
    }

    .info_container {
        display: flex;
        flex-direction: column;
        margin: 2em 0;
        align-items: flex-start;

        .edit {
            height: rem(30px);
            border-radius: rem(15px);
            overflow: hidden;
            display: block;
            cursor: pointer;
            font-family: var(--gotham);
            font-size: rem(18px);
            font-weight: 500;
            line-height: 1.2;
            letter-spacing: 0.1em;
            text-align: center;
            text-transform: uppercase;
            padding: rem(3.2px) rem(20px) rem(5.2px);
            text-align: center;
            text-decoration: none;
            border: 1px solid var(--dark-green);
            box-sizing: border-box;
            transition: color 0.2s, border-color 0.2s, background-color 0.2s;
            background-color: var(--dark-green);
            color: white;
            margin-top: 1em;
            text-align: center;

            &:hover {
                border-color: var(--light-green);
                background-color: var(--light-green);
            }
        }
    }
}
