.ce_downloads {
    margin: 1em 0;

    ul {
        margin: 0;
        padding: 0;
    }
}

.ce_download {
    &.sidebar-element {
        .download-element {
            padding: 20px 0;
            display: flex;
            justify-content: center;

            &:before {
                background-color: transparent;
            }

            span {
                display: none;
            }

            a {
                border-radius: 24px;
                border: rem(1.2px) solid #241201;
                line-height: rem(20px);
                padding: rem(2.8px) rem(14px);
                display: flex;
                justify-content: center;
                align-items: center;
                box-sizing: border-box;
                min-width: vwmax(211px);
                color: #241201;
                font-family: var(--gotham);
                font-size: rem(16px);
                font-weight: 700;
                letter-spacing: 0.2em;
                text-decoration: none;
                text-transform: uppercase;
                transition: color 0.2s, border-color 0.2s, background-color 0.2s;
        
                &:hover {
                    background-color: var(--light-purple);
                    color: white;
                    border-color: var(--light-purple);
        
                    .gray & {
                        background-color: var(--dark-green);
                        border-color: var(--dark-green);
                    }
                    
                    .yellow & {
                        background-color: var(--yellow-light);
                        border-color: var(--yellow-light);
                    }
        
                    .rose-light & {
                        background-color: var(--light-purple);
                        border-color: var(--light-purple);
                    }
                
                    .rose-dark & {
                        background-color: var(--dark-purple);
                        border-color: var(--dark-purple);
                    }
                }
            }
        }
    }
}
