.floats {
    display: none;
    position: fixed;
    right: vwmax(48px);
    top: vwmax(224px);
    z-index: 6;
    transition: transform 0.4s;
    transform: translateX(200px);
    gap: vwmax(14px);
    flex-direction: column;
    align-items: flex-end;

    body.page--index & {
        top: vwmax(274px);
    }

    &.show {
        transform: none;
    }

    @include tablet() {
        display: flex;
    }

    @include breakpoint($base-desktop) {
        right: calc(((100vw - #{$base-desktop}) * 0.5) + 48px);
        transform: translateX(calc(((100vw - #{$base-desktop}) * 0.5) + 200px));
    }

    @media only print {
        display: none !important;
    }
}

.float {
    width: vwmax(75px);
    height: vwmax(75px);
    background-color: var(--dark-green);
    border-radius: vwmax(75px);
    overflow: hidden;
    color: white;
    font-size: vwmax(20px);
    font-family: var(--gotham);
    font-weight: 700;
    box-sizing: border-box;
    padding-right: vwmax(73px);
    transition: width 0.4s;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: flex-end;
    border: vwmax(1px) solid white;
    position: relative;

    a {
        color: inherit;
        text-decoration: none;

        &:hover {
            color: inherit;
            text-decoration: none;
        }
    }

    &__content {
        @include pr();
        width: vwmax(392px - 75px);
        box-sizing: border-box;
        padding-left: vwmax(36px);
        flex-shrink: 0;
        opacity: 0;
        transition: 0.4s;
        line-height: normal;

        .ce_text {
            line-height: inherit;
        }
    }

    &.show {
        width: vwmax(392px);

        .float__content {
            opacity: 1;
        }
    }

    &__button {
        border: 0;
        box-sizing: border-box;
        display: block;
        padding: 0;
        margin: 0;
        background-color: transparent;
        background-repeat: no-repeat;
        width: vwmax(75px);
        height: vwmax(75px);
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
    }

    &--phone {
        .float__button {
            background-image: var(--phone);
            background-size: vwmax(38px) auto;
            background-position: right vwmax(18.47px) center;
        }
    }

    &--podcast {
        .float__button {
            background-image: var(--podcast);
            background-size: vwmax(36px) auto;
            background-position: right vwmax(19px) center;        
        }
    }

    &--shop {
        background-color: var(--light-green);

        .float__button {
            background-image: url('../../images/Icon_Gutscheine.svg');
            background-size: vwmax(75px) auto;
            background-position: center;
        }

        .cart-quantity {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: vwmax(20px);
            bottom: vwmax(18px);
            font-family: var(--work);
            font-size: vwmax(12px);
            font-weight: 500;
            color: var(--light-green);
            z-index: 1;
            width: vwmax(18px);
            text-align: center;
            pointer-events: none;
        }
    }
}
