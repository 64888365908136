@mixin column-classes($max-columns, $class-name) {
    @for $cols from 1 through $max-columns {
        @for $span from 1 through $cols {
            &.-#{unquote($class-name)}-col-#{$cols}-#{$span} {
                grid-column: span #{$span};
            }
        }
    }
}

.ce_rs_columns_start {
    .rs-columns {
        display: grid;
        width: 100%;
        gap: vwmax($column-gutter);

        .ce_accordionStart & {
            gap: rem(40px);
        }
    }

    &.columns--small-gutter .rs-columns {
        gap: rem(32px);
    }

    @for $i from 1 through $columns-mobile {
        &.columns--small--#{$i} > .rs-columns {
            grid-template-columns: repeat(#{$i}, 1fr);
        }
    }

    @include tablet('min') {
        @for $i from 1 through $columns-tablet {
            &.columns--medium--#{$i} > .rs-columns {
                grid-template-columns: repeat(#{$i}, 1fr);
            }
        }
    }

    @include desktop('min') {
        @for $i from 1 through $columns-desktop {
            &.columns--large--#{$i} > .rs-columns {
                grid-template-columns: repeat(#{$i}, 1fr);
            }
        }
    }
}

.rs-column {
    @include column-classes($columns-mobile, 'small');
    min-width: 0;
    max-width: none;

    @include tablet('min') {
        @include column-classes($columns-tablet, 'medium');
    }

    @include desktop('min') {
        @include column-classes($columns-desktop, 'large');
    }
}
