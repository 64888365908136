.ce_rsce_languages_start {
    position: relative;
    padding-top: 20px;

    .languagenav ul {
        position: absolute;
        top: -63px;
        left: 0;
        padding-left: 0;

        li {
            display: inline-block;
            font-weight: lighter;
            cursor: pointer;

            button {
                border: 0;
                padding: 0;
                margin: 0;
                background-color: transparent;
                text-decoration: none;
                font-size: 15.5px;
                text-transform: uppercase;
                font-family: var(--gotham);
                font-weight: 700;
                color: #fff;
                background-color: var(--light-green);
                padding: 8px 18px;
                border-radius: 3px;
                cursor: pointer;

                &.active {
                    background-color: var(--dark-green);
                }
            }
        }
    }

    @include desktop() {
        padding-top: 0;

        .languagenav ul {
            padding-left: initial;
            right: 0;
            top: -114px;
            left: initial;

            li {
                margin-left: 2px;
            }
        }
    }
}
