$s: 0.7;

.newsfilter {
    margin-top: rem(60px);
    margin-bottom: rem(60px);
    font-size: rem(18px);

    input:not([type="radio"]):not([type="checkbox"]),
    select,
    textarea,
    .checkbox_container > span:not(.has-icon),
    .widget-grouped-checkboxes__button {
        border: 1px solid var(--black);
        border-radius: rem(30px);
        height: rem(60px);
        box-sizing: border-box;
        background-color: transparent;
        padding: 0 rem(29px);
        display: block;
        width: 100%;
    }

    select {
        appearance: none;
        background-image: var(--arrow-right-smaller);
        background-size: auto rem(22px);
        background-repeat: no-repeat;
        background-position: right rem(16px) center;
        padding-right: rem(16px + 22px + 16px);
    }

    [type="submit"] {
        --button-text-color: var(--dark-green);
        --button-border-color: var(--dark-green);
        --button-background-color: var(--yet-another-another-light-green);
        --button-hover-text-color: white;
        --button-hover-border-color: var(--dark-green);
        --button-hover-background-color: var(--dark-green);
        @include button();

        & {
            height: rem(48px);
            box-sizing: border-box;
            width: 100%;
            min-width: 155px;
        }

        @include tablet() {
            height: rem(45px);
            font-size: 15px;
            padding: 0 34px;
            min-width: 300px;
        }
    }

    .checkbox_container {
        border: 0;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 1em;

        & > span.has-icon,
        & > span:not(.has-icon) {
            display: flex;
            align-items: center;
            position: relative;

            input {
                position: absolute;
                left: 2px;
                bottom: 2px;
                opacity: 0;
            }

            label {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
            }
        }

        & > span:not(.has-icon) {
            width: 100%;

            label {
                &:before {
                    flex-shrink: 0;
                    display: block;
                    content: '';
                    margin-right: rem(25px);
                    border: 1px solid var(--black);
                    box-sizing: border-box;
                    width: rem(18px);
                    height: rem(18px);
                    border-radius: rem(5px);
                }
            }

            input:checked ~ label:before {
                @include sprite('../../images/newsletter-selected.svg');
            }
        } 
        
        & > span.has-icon {
            label {
                cursor: pointer;
                display: block;
                width: 100px;

                figure {
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    box-sizing: border-box;
                    border: 2px solid transparent;
                    transition: border-color 0.2s;
                    justify-content: center;
                    position: relative;
                    display: flex;
                    align-items: center;
                }

                img {
                    max-width: 70px;
                }

                & > span {
                    display: block;
                    font-size: 0.8em;
                    text-align: center;
                    margin-top: 0.3em;
                }

                @include tablet() {
                    width: 142px;
                    border-radius: 50%;

                    figure {
                        width: 142px;
                        height: 142px;
                    }

                    img {
                        max-width: 100px;
                    }

                    & > span {
                        margin-top: 0;
                        opacity: 0;
                        position: absolute;
                        left: -50%;
                        right: -50%;
                        bottom: -1.5em;
                        transition: opacity 0.2s;
                        pointer-events: none;
                    }

                    &:hover {
                        & > span {
                            opacity: 1;
                        }
                    }
                }
            }

            input:checked ~ label {
                figure {
                    border-color: var(--dark-green);
                }
            }

            input:focus-visible ~ label > span {
                opacity: 1;
            }
        }
    }

    .formbody {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: rem(26px);
        row-gap: rem(31px);

        @include tablet() {
            grid-template-columns: 1fr 1fr;
            column-gap: rem(32px);
            row-gap: rem(39px);
        }

        @include desktop() {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    .widget-grouped-checkboxes {
        position: relative;

        &__button {
            cursor: pointer;
            text-align: left;
            transition: border-radius 0.2s;
            padding-right: rem(29px + 22px + 29px);
            position: relative;

            &:after {
                content: '';
                display: block;
                background-image: var(--arrow-right-smaller);
                background-size: auto rem(22px);
                background-repeat: no-repeat;
                background-position: center;
                width: rem(22px);
                height: rem(22px);
                transition: transform 0.2s;
                position: absolute;
                pointer-events: none;
                right: rem(16px);
                top: 0;
                bottom: 0;
                margin: auto;
            }
        }

        &.open {
            .widget-grouped-checkboxes__button:after {
                transform: rotateZ(90deg);
            }
        }

        &__items {
            position: absolute;
            left: 0;
            top: rem(60px);
            right: 0;
            opacity: 0;
            pointer-events: none;
            visibility: hidden;
            transition: opacity 0.2s, visibility 0.2s, border-radius 0.2s, background-color 0.2s;
            border: 1px solid var(--black);
            border-top: 0;
            border-radius: rem(30px);
            background-color: white;
            z-index: 4;
            max-height: rem(300px);
            overflow: hidden;
            overflow-y: auto;
            scrollbar-width: thin;
        }

        &__checkbox {
            padding: rem(12px * $s) rem(17px * $s);
            position: relative;
            user-select: none;

            input {
                position: absolute;
                left: 5px;
                bottom: 5px;
                opacity: 0;
            }

            label {
                display: flex;
                align-items: center;

                &:before {
                    content: '';
                    display: block;
                    margin-right: rem(10px * $s);
                    border: 1px solid var(--dark-green);
                    box-sizing: border-box;
                    width: rem(35px * $s);
                    height: rem(35px * $s);
                    background-color: white;
                    transition: background-color 0.2s;
                    border-radius: rem(10px * $s);
                    overflow: hidden;
                    flex-shrink: 0;
                }
            }

            &--has-children {
                padding-right: rem(32px + 3px + (17px * $s));
            }

            &__children-toggle {
                border: 0;
                background-color: transparent;
                padding: 0;
                width: rem(38px);
                background-image: var(--arrow-right-smaller);
                background-size: auto rem(14px);
                background-repeat: no-repeat;
                background-position: center center;
                cursor: pointer;
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                transition: transform 0.2s;
            }

            &--open {
                .widget-grouped-checkboxes__checkbox__children-toggle {
                    transform: rotateZ(90deg);
                }
            }

            input[type="radio"] ~ label:before {
                border-radius: 50%;
            }

            input:checked ~ label {
                &:before {
                    background-color: var(--dark-green);
                    background-image: var(--interactive-map-checkbox-checked);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: rem(23px * $s) auto;
                }
            }

            input:checked[type="radio"] + label:before {
                background-image: none;
            }

            @media (pointer:fine) {
                input:active ~ label {
                    &:before {
                        background-color: var(--yet-another-another-light-green);
                    }
                }
            }

            &:hover {
                background-color: #f5f5f5;
            }
        }

        &__subgroup {
            transition: max-height 0.2s, opacity 0.2s;
            max-height: 0;
            opacity: 0;
            overflow: hidden;

            .widget-grouped-checkboxes__checkbox {
                padding-left: rem(17px * 3 * $s)
            }
        }

        &__checkbox--open + .widget-grouped-checkboxes__subgroup {
            max-height: 350px;
            opacity: 1;
        }

        &.open {
            .widget-grouped-checkboxes {
                &__button {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }

                &__items {
                    opacity: 1;
                    pointer-events: all;
                    visibility: visible;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                }
            }
        }
    }

    .widget.widget--newsfilter-icon {
        grid-column: 1 / -1;

        .checkbox_container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            gap: 0.5em;
            row-gap: 1em;
            flex-wrap: wrap;

            @include tablet() {
                row-gap: 0.5em;
            }
        }
    }

    .submit_container {
        grid-column: 1 / -1;
        display: flex;
        align-items: center;
        column-gap: rem(26px);
        row-gap: rem(31px);
        flex-wrap: wrap;
        justify-content: center;

        @include tablet() {
            column-gap: rem(32px);
            row-gap: rem(39px);
        }
    }
}
