.mod_bp_products {
    [type="submit"] {
        @include button();
    }

    .product-sku,
    .product-ean {
        display: none;
    }

    img {
        border-radius: 10px;
    }

    .product-images-wrapper {
        position: relative;
        margin-bottom: 40px;
    }

    .product-images {
        @include listreset();
        position: absolute;
        background: rgba(255, 255, 255, .8);
        border-radius: 10px;
        right: 0px;
        padding: 10px 0px 10px 10px;
        margin: 0px 10px;
        bottom: 10px;
        display: flex;

        li {
            flex: 1 1 0;
            margin-right: 10px;
            max-width: 150px;
        }
    }

    .product-name {
        text-align: center;
        font-family: var(--gotham);
        color: var(--dark-green);
        font-size: 35px;
        margin-bottom: 40px;
        line-height: 1;
    }

    .product-description {
        margin-bottom: 40px;
    }

    .widget-product {
        position: relative;
        padding: 15px 15px 70px 15px;
        border: solid 1px var(--light-green);
        border-radius: 15px;
        display: flex;
        flex-direction: column;

        & > label {
            height: 100%;
        }

        &:after {
            position: absolute;
            left: 15px;
            right: 15px;
            width: calc(100% - 30px);
            content: '';
            border-radius: 10px;
            display: block;
            height: 50px;
            bottom: 15px;
            background-color: var(--light-green);
            transition: all .4s;
        }
    }

    .products__submit {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: fixed;
        align-items: center;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: hsla(0, 0%, 100%, .8);
        box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, .1);
        z-index: 1;
        transform: translateY(160px);
        opacity: 0;
        visibility: hidden;
        transition: all .4s;
        padding: 16px;
        gap: 16px;

        @media only print {
            display: none;
        }        

        & > * {
            width: 100%;
            max-width: 400px;
            margin: 0;
        }

        button {
            font-size: 25px;
            margin: 0px;
        }
    }

    &.show-submit .products__submit {
        opacity: 1;
        visibility: visible;
        transform: translateY(0px);
    }

    .product__input {
        display: flex;
        align-items: center;
        z-index: 1;
        position: absolute;
        left: 15px;
        right: 15px;
        bottom: 15px;
        height: 50px;
        padding: 10px;
        box-sizing: border-box;

        p {
            margin: 0px 20px 0px 0px;
            font-weight: bold;
        }

        input {
            cursor: pointer;
            position: absolute;
            z-index: 2;
            height: 100%;
            left: 0;
            right: 0;
            width: 100%;
            opacity: 0;
        }

        &:after,
        &:before {
            display: block;
            content: '';
            position: absolute;
            border-radius: 50%;
        }

        &:before {
            right: 20px;
            height: 25px;
            width: 25px;
            background-color: #fff;
        }

        &:after {
            height: 17px;
            width: 17px;
            right: 24px;
        }

        &.radio-active {

            &:after {
                background-color: var(--light-green);
            }
        }
    }

    .product-note__inner {
        display: none;

        p {
            margin-top: 0;
        }
    }

    .product-note__action {
        position: relative;
        background-color: var(--light-green);
        padding: 10px;
        border-radius: 10px;
        width: 50%;
        font-weight: bold;
        cursor: pointer;

        &:after {
            display: block;
            position: absolute;
            right: 20px;
            content: '';
            height: 20px;
            width: 20px;
            background-image: url('../../images/green-arrow-down-box.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            top: 0;
            bottom: 0;
            margin: auto;
            transform: rotate(-90deg);
            transition: all .4s;
        }

        &.note-open {

            &:after {
                transform: rotate(0deg);
            }
        }
    }

    .product-note__action.note-open + .product-note__inner {
        display: block;
    }

    @include desktop() {

        .products__list {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 20px;
        }

        .products__submit {
            flex-direction: row;
            padding: 30px;
            gap: 30px;
        }

        .product-images {
            opacity: 0;
            visibility: hidden;
            transform: translateY(10px);
            transition: all .4s;
        }

        .widget-product:hover .product-images {
            opacity: 1;
            visibility: visible;
            transform: translateY(0px);
        }
    }
}
