.bp-voucher {
    display: flex;
    justify-content: center;

    .ce_headline {
        text-align: center;
        padding: 0px 50px;
        position: relative;
        z-index: 1;
        color: #fff;
        font-size: 35px;
        margin: 50px 0;

        span {
            font-size: 50px;
            font-family: var(--thirsty);
            font-weight: bold;
        }

        &:after {
            position: absolute;
            display: block;
            content: '';
            left: 15%;
            right: 15%;
            bottom: -25px;
            border-bottom: solid 3px #fff;
        }
    }
    .inside {
        width: 100%;
        position: relative;
        padding: 10px;
        border-radius: 15px;
        background-color: rgba(37, 114, 54, .9);

        &:before {
            position: absolute;
            content: '';
            display: block;
            left: 10px;
            right: 10px;
            bottom: 10px;
            top: 10px;
            border: solid 1px var(--light-green);
            border-radius: 15px;
        }
    }
}

.mod_bp_voucher_input {
    padding: 0px 50px;
    position: relative;
    z-index: 1;

    .widget-submit {
        margin: 50px 0px;
        display: flex;
        justify-content: center;
    }

    .widget-text {
        text-align: center;

        p.error {
            color: red;
        }

        label {
            color: #fff;
            margin-bottom: 25px;
        }

        input:not([type="radio"]):not([type="checkbox"]) {
            background-color: white;
        }
    }

    @include desktop {

        .widget-text input {
            margin: auto;
            width: 50%;
        }
    }
}
