.header-text {
    display: none;
    color: #333;
    font-family: var(--gotham);
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    body.scroll--sticky & {
        font-size: 12px;
    }
    
    @include menu() {
        transition: margin-top 0.4s;
        display: block;
        font-size: vwmax(15px);
        margin-left: vwmax(32px);
        margin-top: vwmax(46.94px);
        grid-area: phone;
    }

    a {
        color: inherit;
        text-decoration: inherit;

        &:hover {
            color: inherit;
            text-decoration: inherit;      
        }
    }

    &--email {
        a {
            text-decoration: underline;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
