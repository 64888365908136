.box-link {
    position: relative;
    border-radius: vw(30px);
    overflow: hidden;
    min-height: vw(90px);
    background-color: white;
    text-align: center;
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: vw(8px);
    padding: 0 vw(28px);

    &:hover {
        text-decoration: none;
        color: inherit;
    }

    @include tablet() {
        border-radius: vwmax(30px);
        min-height: vwmax(164px);
        gap: vwmax(24px);
        padding: 0 vwmax(30px);
    }

    &__background {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        opacity: 0.35;
        transition: opacity 0.2s;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
    }

    &:hover {
        .box-link__background {
            opacity: 1;
        }
    }

    &__headline,
    &__text {
        position: relative;
    }

    &__headline {
        color: var(--dark-green);
        font-family: var(--gotham);
        font-size: vw(22px);
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
        border: 0;
        line-height: 0.9;
        letter-spacing: normal;

        @include tablet() {
            font-size: vwmax(32px);
        }

        .nig-styled {
            display: block;
            color: inherit;
            font-family: inherit;
            font-weight: inherit;
            font-style: inherit;
            font-size: vw(22px);
            line-height: 0.9;

            @include tablet() {
                font-size: vwmax(25px);
            }
        }

    }

    &__text {
        @include pr();
        font-size: vw(12px);

        @include tablet() {
            font-size: vwmax(18px);
        }
    }
}
