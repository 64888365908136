:root {
    --text-size: var(--text-size-mobile);

    @include tablet() {
        --text-size: var(--text-size-desktop);
    }
}

html {
    font-family: var(--work);
    color: var(--text-color);
    font-size: var(--text-size);
}

h1 {
    @include h1();
}

h2 {
    @include h2();
}

h3 {
    @include h3();
}

h4 {
    @include h4();
}

a {
    color: inherit;
    text-decoration: underline;
    transition: color 0.2s;

    &:hover {
        color: var(--light-green);
        text-decoration: none;
    }
}

h1, h2, h3, h4, h5, h6 {
    a {
        text-decoration: inherit;
        color: inherit;

        &:hover {
            text-decoration: inherit;
            color: inherit;
        }
    }
}

figure {
    position: relative;

    figcaption {
        display: block;
        background-color: var(--dark-green);
        color: white;
        font-family: var(--work);
        font-size: rem(12px);
        font-weight: 400;
        line-height: rem(16px);
        padding: rem(7px) rem(14px);
        text-align: center;
        position: absolute;
        left: 0; right: 0;
        bottom: 0;

        body.page--index & {
            display: none;
        }
    }
}

hr {
    border-style: dashed;
    border-color: black;
    border-width: 1px;
}

.headline--upper {
    h1, h2, h3, h4, h5, h6 {
        text-transform: uppercase;
    }
}

h1, h2, h3, h4, h5, h6 {
    &.headline--upper {
        text-transform: uppercase;
    }
}

.headline--green {
    h1, h2, h3, h4, h5, h6 {
        color: var(--dark-green);
    }
}

h1, h2, h3, h4, h5, h6 {
    &.headline--green {
        color: var(--dark-green);
    }
}

.text--uppercase {
    text-transform: uppercase;
}

b, strong {
    font-weight: 600;
}

.light-margin-top {
    margin-top: 2em !important;
}

.light-margin-bottom {
    margin-bottom: 2em !important;
}
