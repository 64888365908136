.hyperlink {
    &--button {
        --button-text-color: var(--dark-green);
        --button-background-color: white;
        --button-border-color: var(--dark-green);
        --button-hover-background-color: var(--button-text-color);
        --button-hover-text-color: var(--button-background-color);
        --button-hover-border-color: var(--button-border-color);
        display: flex;
        margin: 38px 0;

        @include tablet() {
            margin: 60px 0;
        }

        a {
            @include button();

            & {
                min-width: vw(250px);
                box-sizing: border-box;
                border-radius: 60px;
                height: 30px;
            }

            @include tablet() {
                min-width: vwmax(514px);
                height: 65px;
            }
        }

        &--center {
            justify-content: center;
        }

        &--dark-green {
            --button-text-color: white;
            --button-background-color: var(--dark-green);
            --button-border-color: var(white);
        }

        &--light-green {
            --button-text-color: white;
            --button-background-color: var(--light-green);

            a {
                border: 0;
            }
        }

        &--pink {
            --button-text-color: var(--dark-purple);
            --button-background-color: var(--lighter-purple);
            --button-hover-text-color: white;
            --button-hover-background-color: var(--another-light-purple);

            a {
                border: 0;
            }
        }

        &--purple {
            --button-text-color: white;
            --button-background-color: var(--dark-purple);

            a {
                border: 0;
            }
        }

        &--slightly-bigger {
            a {
                @include tablet() {
                    height: 60px;
                    font-size: 22px;
                    min-width: vwmax(680px);
                    letter-spacing: 0.1em;
                }
            }
        }

        &--more-margin {
            @include tablet() {
                margin-top: 90px;
                margin-bottom: 90px;
            }
        }

        &--noupper {
            a {
                text-transform: none;
            }
        }
    }
}
