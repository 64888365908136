.search--header {
    position: absolute;
    width: 43.5px;
    height: 43.5px;
    right: 44.5px;
    top: 3.5px;

    .widget-text {
        transition: opacity 0.2s, visibility 0.2s;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;

        input {
            width: vw(240px);
            height: 43.5px;
            display: block;
            box-sizing: border-box;
            border-radius: 22.5px;
            border: 0.5px solid black;
            padding: 0;
            background-color: white;
            padding-left: 21px;
            padding-right: 43.5px;
        }

        body.show-search & {
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
        }
    }

    [type="submit"] {
        mask-image: var(--search);
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: 22.5px auto;
        background-color: black;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 43.5px;
        height: 43.5px;
        border: 0;
        padding: 0;
        margin: auto;
        text-indent: 100px;
        white-space: nowrap;
        overflow: hidden;
        cursor: pointer;
    }

    @include menu() {
        display: block;
        position: relative;
        right: 0;
        top: 0;
        width: vwmax(58px);
        height: vwmax(58px);
        grid-area: search;
        align-self: flex-end;
        justify-self: flex-start;
        margin-bottom: vwmax(6px);
        margin-left: vwmax(24px);

        body.scroll--sticky & {
            width: vwmax(38.6666px);
            height: vwmax(38.6666px);
            margin-left: vwmax(16px);
            margin-bottom: 0;
            align-self: center;
            margin-left: vwmax(36px);
            margin-bottom: vwmax(2px);
        }

        body.layout--international & {
            margin-left: auto;
            margin-right: vwmax(144px);
        }
    
        .widget-text {   
            input {
                width: vwmax(349px);
                height: vwmax(58px);
                border-radius: vwmax(30px);
                border: vwmax(0.5px) solid var(--dark-green);
                padding-left: vwmax(28px);
                padding-right: vwmax(58px);

                body.scroll--sticky & {
                    width: vwmax(232.6666px);
                    height: vwmax(58px);
                    border-radius: vwmax(20px);
                    padding-left: vwmax(18.6666px);
                    height: vwmax(38.6666px);
                }
            }
        }
    
        [type="submit"] {
            width: vwmax(58px);
            height: vwmax(58px);
            mask-size: vwmax(30px) auto;
            background-color: var(--dark-green);

            body.scroll--sticky & {
                width: vwmax(38.6666px);
                height: vwmax(38.6666px);
                mask-size: vwmax(20px) auto;
            }
        }
    }
}
