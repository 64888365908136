.mod_iso_productreader {
    .inside {
        @include tablet() {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 50%));
            gap: 34px;
        }

        .image_container {
            border-radius: rem(40px);
            overflow: hidden;
            align-self: flex-start;

            img {
                width: 100%;
            }
        }

        .content {
            [itemprop="name"] {
                font-family: var(--gotham);
                font-size: rem(35px);
                font-weight: 700;
                line-height: 1.2;
                letter-spacing: 0.03em;
                text-align: left;
                margin-bottom: rem(16px);
                margin-top: rem(24px);

                @include tablet() {
                    margin-top: 0;
                }
            }

            .description {
                font-family: var(--work);
                font-size: rem(20px);
                font-weight: normal;
                line-height: 1.5;
                letter-spacing: 0.03em;
            }

            .quantity_container {
                label {
                    display: none;
                }

                select {
                    border: 1px solid var(--black);
                    border-radius: rem(30px);
                    height: rem(60px);
                    box-sizing: border-box;
                    background-color: transparent;
                    padding: 0 rem(21px);
                    display: block;
                    width: 100%;
                    appearance: none;
                    padding-right: rem(45px + 29px + 15px);
                    background-image: url('../../images/arrow-down-green.svg');
                    background-size: rem(29px) auto;
                    background-position: right rem(20px) center;
                    background-repeat: no-repeat;
                }
            }

            .offer {
                text-align: right;
                font-family: var(--gotham);
                font-size: rem(40px);
                font-weight: 700;
                line-height: 1.2;
                letter-spacing: 0.03em;
                align-self: flex-end;
            }

            .add_to_cart {
                @include button();

                & {
                    margin-top: rem(20px);
                    width: 100%;
                    font-weight: 500;
                    padding-left: rem(15px);
                    padding-right: rem(15px);
                    letter-spacing: 0.1em;
                }
            }

            .offer {
                margin-bottom: rem(20px);
            }

            @include desktop() {
                display: grid;
                grid-template-columns: minmax(0, 320px) minmax(0, 1fr);
                gap: 1em;

                .text-content {
                    grid-column: auto / span 2;
                }

                .submit_container {
                    grid-column-start: 1;
                    align-self: flex-end;
                }

                .offer {
                    order: 99;
                    grid-column-start: 2;
                    margin-bottom: 0;
                }
            }
        }
    }
}
