.international-map {
    margin-bottom: 32px;
    position: relative;

    @include tablet() {
        margin-bottom: 80px;
        margin-left: vwmax(-88px);
        margin-right: vwmax(-88px);
    }

    &__headline,
    &__text {
        @include tablet() {
            display: none;
        }
    }

    &__headline {
        @include tablet('max') {
            margin-bottom: 10px;
        }
    }

    &__text {
        margin-top: 10px;
        margin-bottom: 20px;
    }

    &__svg {
        pointer-events: none;
        border-bottom-left-radius: vw(20px);
        border-bottom-right-radius: vw(20px);
        overflow: hidden;
        display: none;

        @include tablet() {
            border-bottom-left-radius: vwmax(40px);
            border-bottom-right-radius: vwmax(40px);
            display: block;
        }

        svg {
            display: block;
        }

        [id*="_plain"] {
            transition: opacity 0.2s;
            pointer-events: auto;

            &.can-click {
                cursor: pointer;
    
                &.show-hover {
                    opacity: 0;
                }
            }
        }
    }

    &__entries {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: vw(14px);
        row-gap: vw(14px);

        @include tablet() {
            display: none;
        }
    }

    &__entry {
        overflow: hidden;
        border-radius: vw(20px);
        position: relative;

        &-title {
            position: absolute;
            left: vw(14px);
            bottom: vw(30px);
            color: white;
            text-shadow: 1px 1px 1px rgba(36, 18, 1, 0.70);
            font-family: var(--gotham);
            font-size: vw(20px);
            font-style: normal;
            font-weight: 700;
            line-height: 1.2;
        }

        &-image {
            img {
                width: 100%;
            }
        }
    }

    &__menus {
        display: none;
        position: absolute;
        top: vwmax(90px);
        left: vwmax(952px);

        @include tablet() {
            display: block;
        }
    }

    &__menu {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: opacity 0.2s, visibility 0.2s;
        border-radius: vwmax(30px);
        overflow: hidden;
        font-size: vwmax(18px);
        padding: vwmax(27px) vwmax(14px) vwmax(35px) vwmax(36px);
        width: vwmax(487px);
        box-sizing: border-box;
        background-color: white;
        position: absolute;
        left: 0;
        top: 0;

        a {
            text-decoration: none;
        }

        &.show-menu {
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
        }

        &-title {
            font-size: vwmax(24px);
            font-family: var(--gotham);
            font-weight: 700;
            margin-bottom: 0.85em;
        }

        &-items {
            list-style: none;
            margin: 0;
            padding: 0;
            margin-left: 0.9em;
        }
    }
}
