/* Gotham Ronded */
@font-face {
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local(''), url('../../fonts/gotham-rounded/GothamRnd-Bold_Web.woff2') format('woff2');
}

@font-face {
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local(''), url('../../fonts/gotham-rounded/GothamRnd-Medium_Web.woff2') format('woff2');
}

@font-face {
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local(''), url('../../fonts/gotham-rounded/GothamRnd-Light_Web.woff2') format('woff2');
}

/* Work Sans */
@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local(''), url('../../fonts/work-sans/work-sans-v19-latin_latin-ext-300.woff2') format('woff2');
}

@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''), url('../../fonts/work-sans/work-sans-v19-latin_latin-ext-regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Work Sans';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local(''), url('../../fonts/work-sans/work-sans-v19-latin_latin-ext-italic.woff2') format('woff2');
}

@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local(''), url('../../fonts/work-sans/work-sans-v19-latin_latin-ext-500.woff2') format('woff2');
}

@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local(''), url('../../fonts/work-sans/work-sans-v19-latin_latin-ext-600.woff2') format('woff2');
}

@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local(''), url('../../fonts/work-sans/work-sans-v19-latin_latin-ext-700.woff2') format('woff2');
}

/* Thirsty Script */
@font-face {
    font-family: 'Thirsty Script';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local(''), url('../../fonts/thirsty/396EB4_8_0.woff2') format('woff2');
}

@font-face {
    font-family: 'Thirsty Script';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local(''), url('../../fonts/thirsty/396EB4_1_0.woff2') format('woff2');
}
