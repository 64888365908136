h2.ce_headline.headline--special,
.ce_text.headline--special h2 {
    color: var(--black);
    font-family: var(--gotham);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 30px;
    margin-bottom: 24px;

    .nig-styled {
        font-size: 2.0909em;

        @include tablet() {
            font-size: 1.666em;
        }
    }
    
    @include tablet() {
        font-size: 30px;
        margin-top: 60px;
        margin-bottom: 60px;
    }
}

.ce_headline {
    .mod_article.article--columns &,
    .mod_article.article--box-links & {
        grid-column: 1 / -1;
    }
}
