#header {
    padding-left: 36px;
    padding-right: 15px;
    max-width: 1615px;
    margin: 0 auto;
    margin-top: 40px;
    position: relative;
    z-index: 9;
    position: fixed;
    left: 0; top: 0; right: 0;

    & > .inside {
        display: flex;
    }

    @include menu() {
        padding-left: vwmax(84px);
        padding-right: vwmax(25px);
        margin-top: 0;
        transition: transform 0.4s;
        position: relative;
        left: auto; top: auto; right: auto;

        & > .inside { 
            position: relative;         
            margin-left: vwmax(211px);
            margin-right: vwmax(161px);
            display: grid;
            grid-template-columns: auto 1fr;
            grid-template-areas: 
                'phone phone '
                'menu  search'
            ;

            &:before {
                content: '';
                display: table;
            }

            &:after {
                content: '';
                display: block;
                height: 0;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                border-bottom: vwmax(1px) solid var(--dark-green);
                z-index: 5;
                pointer-events: none;
            }
        }
    }
}

@include menu() {
    body.scroll--sticky {
        #header {
            @include fwbg(white);
            height: vwmax(133px);
            position: fixed;
            left: 0; top: vwmax(-133px); right: 0;
            transform: translateY(vwmax(133px));

            .header-logo {
                width: vwmax(139.846px);
            }

            .header-phone {
                margin-top: vwmax(16px);
            }

            .navigation--main {
                margin-top: 0;
            }
        }

        #container {
            padding-top: vwmax(133px);
        }
    }

    body.scroll--sticky.scroll--up:not(.scroll--th2) {
        #header {
            transform: none;
        }
    }
}
