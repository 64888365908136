@keyframes cookiemonster {
    0% {
        transform: translateX(-72px) rotate(90deg);
    }
    60% {
        transform: translateX(-72px) rotate(90deg);
    }
    70% {
        transform: translateX(0) rotate(90deg);
    }
    90% {
        transform: translateX(0) rotate(90deg);
    }
    100% {
        transform: translateX(-72px) rotate(90deg);
    }
}

.cookiebar_default {
    .cc-head {
        & > * {
            &:first-child {
                margin-top: 0;
            }
        }
    }

    .cc-inner {
        position: relative;

        &:before {
            /*
            @include sprite('../images/cookie-monster.gif');
            content: '';
            width: 150px;
            height: 72px;
            position: absolute;
            left: 0;
            top: -36px;
            transform: translateX(-72px) rotate(90deg);
            display: block;
            margin: auto;
            transform-origin: bottom left;
            animation-name: cookiemonster;
            animation-duration: 5s;
            animation-iteration-count: infinite;
            animation-fill-mode: forwards;
            pointer-events: none;
            */
        }
    }

    
    input + label:before{
        top: 9px;
    }

    input + label:after {
        top: 13px;
    }

    .cc-groups {
        margin-top: 18px;
        margin-bottom: 18px;
    }

    .cc-group {
        .cc-detail-btn {
            margin: 0;
        }

        & > label {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}
