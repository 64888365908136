.menu-button {
    border: 0;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 43px;
    height: 43px;
    cursor: pointer;
    position: absolute;
    background: none;
    right: 0px;
    top: 5px;

    &__line {
        transition: transform 0.2s;
        transform-box: fill-box;
        transform-origin: 50% 50%;

        body.show-menu & {
            &--1 {
                transform: translateY(4px) rotate(22deg);
            }
    
            &--2 {
                transform: translateY(-4px) rotate(-22deg);
            }
        }
    }

    @include menu() {
        display: none;
    }

    @media only print {
        display: none;
    }
}
