.header-logo {
    margin-left: -22px;

    @include menu() {
        margin-left: 0;
        margin-top: 0;
        position: absolute;
        top: vwmax(14.38px);
        left: vwmax(-59.89px - 211px);
    }

    img {
        width: 86px;
        flex-shrink: 0;

        @include menu() {
            width: vwmax(235.813px);
        }
    }

    &--international {
        @include menu() {
            top: vwmax(21px);
        }

        img {
            @include menu() {
                width: vwmax(230px);
            }
        }
    }
}

.footer-logo {
    @include larger() {
        margin-top: vwmax(-24px);
    }

    img {
        margin: auto;
        width: 122px;

        @include larger() {
            margin: 0;
            width: vwmax(251px);
        }
    }
}

.side-logo {
    display: none;
    grid-area: search;
    margin-left: auto;

    img {
        width: vwmax(105px);

        @include menu() {
            body.scroll--sticky & {
                width: vwmax(70px);
            }
        }
    }

    @include tablet() {
        display: block;
    }
}
