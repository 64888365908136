.mod_breadcrumb {
    margin-top: rem(68px);
    margin-bottom: rem(42px);
    font-size: rem(18px);
    font-weight: 400;
    letter-spacing: 0.05em;

    a {
        text-decoration: none;
    }

    & > ul {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        list-style: none;

        & > li {
            &:after {
                content: '\00a0\00a0|\00a0\00a0';
            }

            &:last-child {
                &:after {
                    display: none;
                }

                & > a,
                & > span,
                & > strong {
                    font-weight: 700;
                }
            }
        }
    }
}
