.ce_rsce_newsmap {
    overflow: hidden;
    border-radius: vwmax(40px);

    .-large-first & {
        padding-bottom: 0;
        margin-bottom: 0;
    }

    iframe.google-map,
    div.google-map {
        height: vw(260px);

        @include tablet() {
            height: vwmax(430px);
        }
    }
}

.interactivemap__googlemap,
.ce_rsce_newsmap {
    .cc-module {
        height: 100%;
        font-size: 14px;
        font-family: sans-serif;
        text-align: center;
        color: #6f6f6f;

        p {
            margin-bottom: 1em;
        }

        .cc-btn {
            display: inline-block;
            cursor: pointer;
            padding: 8px 14px;
            font-size: 15px;
            border: 1px solid #cfcfcf;
            border-radius: 4px;
            color: #444;
            background: #f5f5f5;
        }
    }
}
