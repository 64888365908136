.slider-image {
    margin-top: -52px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;

    @include tablet() {
        margin-left: vwmax(-88px);
        margin-right: vwmax(-147px);
    }

    @include menu() {
        margin-top: -37px;
    }

    &--add-gradient:before {
        position: absolute;
        left: 0; top: 0; right: 0;
        height: vw(120px);
        background-image: linear-gradient(to bottom, rgba(255,255,255,1.0), rgba(255,255,255,0));
        display: block;
        content: '';
        z-index: 1;
    }

    &__bar {
        content: '';
        display: block;
        width: vw(271px);
        margin: auto;
        border-bottom: vw(2px) solid var(--light-green);
    }

    &__image {
        img {
            width: 100%;
        }
    }

    &__content {
        position: absolute;
        top: vw(56px);
        left: vw(49px);
        right: vw(49px);
        z-index: 2;
    }

    &__text {
        &-top {
            @include pr();
            font-family: var(--thirsty);
            color: var(--almost-black);
            font-size: vw(17px);
            font-weight: 500;
            line-height: 1.17647;
        }

        &-bottom {
            @include pr();
            margin-top: vw(7px);
            color: var(--almost-black);
            font-family: var(--gotham);
            font-size: vw(8px);
            font-weight: 700;
        }
    }

    &__links {
        margin-top: vw(26px);
        display: flex;
        flex-wrap: wrap;
        gap: vw(5px);
    }

    &__link {
        @include button();
        font-size: vw(9px);
        height: vw(32px);
        padding: 0 vw(14px);
    }

    @include tablet() {
        margin-top: vwmax(-79px);

        &--add-gradient:before {
            height: vwmax(330px);
        }

        &__bar {
            width: vwmax(1166px);
            border-width: vwmax(7px);
        }

        &__content {
            top: vwmax(163px);
            left: vwmax(243px);
            right: vwmax(243px);
        }

        &__text {
            &-top {
                font-size: vwmax(50px);
                font-style: normal;
                font-weight: 500;
                line-height: 1.1;
            }

            &-bottom {
                margin-top: vwmax(12px);
                font-size: vwmax(20px);
            }
        }

        &__links {
            margin-top: vwmax(38px);
            gap: vwmax(10px);
        }

        &__link {
            font-size: vwmax(20px);
            height: vwmax(60px);
            padding: 0 vwmax(60px);
        }
    }
}
