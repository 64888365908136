.regionalstandorte {
    @include tablet() {
        width: vwmax(910px);
    }

    &__headline {
        margin-bottom: 24px;

        @include tablet() {
            margin-bottom: 76px;
        }
    }

    &__inner {
        @include tablet() {
            display: flex;
        }
    }

    &__container {
        position: relative;
    }

    &__map {
        display: none;

        @include tablet() {
            display: block;
        }
    }

    &__standort {
        margin-top: 30px;
        margin-bottom: 30px;
        box-sizing: border-box;
        background-color: white;
        letter-spacing: 0.05em;
        padding-left: 24px;
        padding-right: 24px;
        transition: background-color 0.2s;
        pointer-events: none;
        border-radius: 30px;
        border: 1px solid var(--light-green);
        padding-top: 18px;
        font-size: 16px;
        padding-bottom: 31px;

        @include tablet() {
            position: absolute;
            margin-top: 0;
            margin-bottom: 0;
            padding-left: 1em;
            padding-right: 1em;
            background-color: transparent;
            border-radius: vwmax(40px);
            margin-left: vwmax(382px * -0.5);
            margin-top: vwmax(-25px - 31.792px * 0.5);
            width: vwmax(382px);
            border: vwmax(1px) solid transparent;
            padding-top: vwmax(24px);
            font-size: vwmax(18px);
            padding-bottom: vwmax(26px);
            text-align: center;

            &--zwettl {
                left: vwmax(301px + 31.792px * 0.5);
                top: vwmax(97px + 31.792px * 0.5);
            }
    
            &--gaenserndorf {
                left: vwmax(773px + 31.792px * 0.5);
                top: vwmax(267px + 31.792px * 0.5);
            }
    
            &--tulln {
                left: vwmax(532px + 31.792px * 0.5);
                top: vwmax(329px + 31.792px * 0.5);
            }
    
            &--st-poelten {
                left: vwmax(407px + 31.792px * 0.5);
                top: vwmax(405px + 31.792px * 0.5);
            }
    
            &--waidhofen {
                left: vwmax(124px + 31.792px * 0.5);
                top: vwmax(536px + 31.792px * 0.5);
            }
    
            &--baden {
                left: vwmax(594px + 31.792px * 0.5);
                top: vwmax(499px + 31.792px * 0.5);
            }
        }

        &-button {
            background-color: var(--light-green);
            padding: 0;
            border: 0;
            cursor: pointer;
            margin: 0 auto;
            pointer-events: auto;
            width: vwmax(31.792px);
            height: vwmax(31.759px);
            border-radius: vwmax(31.792px * 0.5);
            display: none;

            @include tablet() {
                display: block;
            }
        }

        &-headline {
            font-family: var(--gotham);
            font-weight: 700;
            line-height: normal;
            letter-spacing: 0.1em;
            text-align: center;
            color: var(--dark-green);
            font-size: rem(25px);
            margin-top: 0;
            margin-bottom: 16px;

            @include tablet() {
                color: black;
                font-size: vwmax(25px);
                margin-top: vwmax(13.24px);
                margin-bottom: vwmax(23px);
            }
        }

        &-text {
            @include pr();

            @include tablet() {
                pointer-events: none;
                opacity: 0;
                transition: opacity 0.2s;
            }
        }

        @include tablet() {
            &.show {
                background-color: white;
                border-color: var(--light-green);
                z-index: 10;
                pointer-events: auto;

                .regionalstandorte {
                    &__standort {
                        &-text {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
