.ce_image {
    margin: 2em 0;

    &:first-child,
    .ce_rsce_swiper_start & {
        margin-top: 0;
    }

    figure.image_container {
        img {
            overflow: hidden;
            border-radius: rem(50px);
            display: block;
        }
    }

    &:not(.image-original-size) {
        figure.image_container {   
            img {
                width: 100%;
            }
        }
    }
}
