.schaugarten-teaser {
    $root: &;
    border-radius: rem(30px);
    overflow: hidden;
    box-sizing: border-box;
    border: 1px solid var(--black);
    position: relative;
    font-size: rem(18px);

    &__content {
        padding: 0 rem(18px);
    }

    &__headline {
        color: var(--dark-green);
        font-family: var(--work);
        font-size: rem(28px);
        font-style: normal;
        font-weight: 700;
        line-height: 1.16667;
        letter-spacing: 0.03em;
        margin-top: rem(28px);
        margin-bottom: rem(22px);
    }

    &__image {
        img {
            width: 100%;
        }
    }

    &__opening-hours {
        position: absolute;
        left: 0;
        right: 0;
        border-radius: 0 0 rem(30px) rem(30px);
        overflow: hidden;
        z-index: 1;
        background-color: white;
        padding: rem(34px) rem(21px);
        display: none;

        &-title {
            margin-bottom: 0;
            font-weight: 700;
            font-family: inherit;
            font-size: inherit;
            margin-top: 0;
        }

        &-text {
            @include pr();
        }
    }

    &:hover {
        #{$root} {
            &__opening-hours {
                display: block;
            }
        }
    }

    &__image {
        img {
            width: 100%;
        }
    }

    &__sg-des-jahres {
        float: right;
        margin-top: -0.85em;
        margin-left: 0.85em;
    }
}
