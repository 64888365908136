.ce_rsce_news_week_filter {
    margin-top: rem(100px);
    margin-bottom: rem(50px);

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        gap: vwmax(32px);
        flex-wrap: wrap;
    }

    li {
        flex-shrink: 0;

        & > a,
        & > span,
        & > strong {
            --button-background-color: white;
            --button-text-color: var(--dark-green);
            --button-border-color: var(--dark-green);
            --button-hover-background-color: var(--dark-green);
            --button-hover-text-color: white;
            --button-hover-border-color: var(--dark-green);
            @include button();

            & {
                height: rem(60px);
                box-sizing: border-box;
                font-size: rem(18px);
                letter-spacing: 0.2em;
                min-width: vwmax(438px);
            }

            @include tablet() {
                font-size: rem(18px);
                height: rem(60px);
            }
        }

        &.active {
            & > a,
            & > span,
            & > strong {
                --button-background-color: var(--dark-green);
                --button-text-color: white;
                --button-border-color: var(--dark-green);
                --button-hover-background-color: white;
                --button-hover-text-color: var(--dark-green);
                --button-hover-border-color: var(--dark-green);
            }
        }
    }
}
