.ce_form,
.mod_mailchimp_subscribe,
.search--results,
.mod_bp_voucher_input,
.mod_bp_order,
#form-content,
.mod_iso_checkout .address,
.mod_iso_checkout .payment {
    fieldset {
        display: block;
        border: 0;
        padding: 0;
        margin: 0;
    }

    input:not([type="radio"]):not([type="checkbox"]),
    select,
    textarea {
        border: 1px solid var(--black);
        border-radius: rem(27.5px);
        height: rem(55px);
        box-sizing: border-box;
        background-color: transparent;
        padding: 0 rem(45px);
        display: block;
        width: 100%;

        @include tablet() {
            margin: 0 rem(-26px) 0 rem(-45px);
            width: calc(100% + #{rem(71px)});
        }
    }

    select {
        appearance: none;
        padding-right: rem(45px + 29px + 15px);
        background-image: url('../../images/arrow-down-green.svg');
        background-size: rem(29px) auto;
        background-position: right rem(20px) center;
        background-repeat: no-repeat;
    }

    textarea {
        box-sizing: border-box;
        padding-top: rem(16px);
        padding-bottom: rem(16px);
        overflow: auto;
        scrollbar-width: thin;
        resize: vertical;
    }

    p.error {
        color: red;
    }

    .formbody,
    .formbody > fieldset,
    .formbody > .fields,
    .formbody > .fields > fieldset,
    .address_new {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: rem(34px);
        column-gap: rem(56px);
    }

    form.two-columns {
        .formbody,
        .formbody > fieldset,
        .formbody > .fields,
        .formbody > .fields > fieldset {  
            @include tablet() {
                grid-template-columns: 1fr 1fr;
            }
        }
    }

    [type="submit"] {
        @include button();
    }
    
    fieldset .widget{
        & > label {
            font-size: 1rem;
        }
    
    }

    .widget {
        @include tablet() {
            padding: 0 rem(26px) 0 rem(45px);
        }

        & > fieldset > legend,
        & > label {
            display: block;
            margin-bottom: rem(16px);
            font-size: rem(24px);
            line-height: 1;
        }

        & > .radio_container,
        & > .checkbox_container {
            display: flex;
            flex-wrap: wrap;
            column-gap: rem(56px);
            row-gap: 1em;

            & > span {
                position: relative;
            }

            input {
                position: absolute;
                left: 2px;
                bottom: 2px;
                opacity: 0;
            }

            label {
                display: flex;
                align-items: center;
                width: 100%;

                &:before {
                    flex-shrink: 0;
                    display: block;
                    content: '';
                    margin-right: rem(25px);
                    width: rem(20px);
                    height: rem(20px);
                    border: 1px solid var(--black);
                    border-radius: rem(8px);
                    box-sizing: border-box;
                }
            }

            input:checked ~ label:before {
                background-color: var(--black);
                box-shadow: inset 0 0 0 2px white;
            }
        }

        & > .checkbox_container {
            flex-direction: column;

            & > span {
                display: block;
            }

            label {
                &:before {
                    width: rem(18px);
                    height: rem(18px);
                    border-radius: rem(5px);
                }
            }

            input:checked ~ label:before {
                @include sprite('../../images/newsletter-selected.svg');
                background-color: transparent;
                box-shadow: none;
            }
        }

        &-explanation {
            p, h1, h2, h3, h4, h5, h6 {
                &:first-child {
                    margin-top: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &-submit,
        &-explanation,
        &-accordion {
            grid-column: 1 / -1;
        }

        &-fineuploader {
            .qq-uploader-selector {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }

            .qq-upload-button {
                --button-border-color: var(--dark-purple);
                --button-text-color: white;
                --button-background-color: var(--dark-purple);
                --button-hover-background-color: var(--button-text-color);
                --button-hover-text-color: var(--button-background-color);
                --button-hover-border-color: var(--button-border-color);
                @include button();

                & {
                    height: rem(60px);
                    box-sizing: border-box;
                    font-size: rem(18px);
                    letter-spacing: 0.2em;
                    text-transform: uppercase;
                    position: relative;
                }

                input {
                    cursor: pointer;
                }

                @include tablet() {
                    height: rem(60px);
                    font-size: rem(18px);
                }
            }   
        }
    }

    .widget,
    fieldset {
        &.full-width {
            grid-column: 1 / -1;
        }

        &.cl {
            grid-column-start: 1;
        }

        &.margin-top {
            margin-top: rem(34px);
        }

        &.two-columns{
            display: grid;
            grid-template-columns: 1fr 1fr;
            row-gap: rem(34px);
            column-gap: rem(56px);
        }
    }
}

.bold-label legend {
	font-weight: bold;
}

.bold-label label {
	font-weight: bold;
}

.bold-legend legend {
	font-weight: bold;
}