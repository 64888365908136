.ce_survey {
    fieldset {
        margin: 0;
        padding: 0;
        border: 0;
    }

    #ctrl_25, #ctrl_9, #ctrl_24, #ctrl_56, #ctrl_19, #ctrl_52, #ctrl_14 {
        label:first-child {
            display: none;
        }
    }
    .qid-17, .qid-16, .qid-15, .qid-1{
        p {
            margin: 15px 0 0px 0!important;
            font-size: 22px!important;
            font-weight: bold!important;
            font-stretch: normal!important;
            font-style: normal!important;
            line-height: 1.09!important;
            letter-spacing: normal!important;
            text-align: left!important;
            color: #257236!important;
        }

    }
    .qid-14 {
        .title{
            color: transparent!important;

            .mandatory{
                display: none;
            }

            .help{
                color: #257236!important;
            }
        }
    }
    span.mandatory{
        display: none;
    }
    p.error{
        color: red!important;
        font-weight: bold!important;
        font-size: 0.8em!important;
        margin-bottom: 5px !important;
    }


    .progressbar, .pagetitle{
        display: none;
    }

    .pagetitle {
        margin: 57px 0 13px 0;
        font-size: 28px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.79;
        letter-spacing: normal;
        text-align: left;
        color: #257236;
    }
    .widget{
        margin: 15px 0;
    }

    .question p {
        margin: 15px 0 0px 0;
        font-size: 19px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.59;
        letter-spacing: normal;
        text-align: left;
        color: #000;
    }

    input[type="text"] {
        border: 1px solid #6f6f6f;
        border-radius: 9px;
        height: 48px;
        box-sizing: border-box;
    }

    [type="submit"] {
        @include button();
    }

    .input_container {
        & > div {
            display: block;
            position: relative;

            & + div {
                margin-top: 0.5em;
            }

            input {
                position: absolute;
                left: 0;
                bottom: 0;
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
            }

            label {
                display: flex;
                align-items: center;

                &:before {
                    @include sprite('../../images/Radio_nicht-geklickt.svg');
                    content: '';
                    display: block;
                    margin-right: 20px;
                    min-width: 19px;
                    min-height: 19px;
                }
            }
        }

        &.horizontal {
            display: block;

            @include tablet(){
                display: flex;
            }

            & > div {
                & + div {
                    margin-top: 0;
                    margin-left: 0;

                    @include tablet(){
                        margin-left: 56px;
                    }
                }

                label {
                    flex-direction: column;
                    align-items: center;

                    &:before {
                        order: 100;
                        margin-right: 0;
                        margin-top: 10px;
                    }
                }
            }
        }
    }

    .radio_container {
        & > div {
            label:before {
                background-image: url('../../images/Radio_nicht-geklickt.svg');
            }

            input:checked + label:before {
                background-image: url('../../images/Radio_angeklickt.svg');
            }
        }
    }

    .checkbox_container {
        & > div {
            label:before {
                background-image: url('../../images/check_nicht-geklickt.svg');
            }

            input:checked + label:before {
                background-image: url('../../images/check_angeklickt.svg');
            }
        }
    }

    .submit_container {
        margin-top: 2em;
    }

    .surveyblock {
        & > .title {
            margin: 42px 0 0px 0;
            font-size: 22px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.09;
            letter-spacing: normal;
            text-align: left;
            color: #257236;
        }
        
    }
}
.umfrage{
    .register-button a{
        max-width: 400px;
        margin-bottom: 20px;
    }
}

.sg-des-jahres{
    .radio_container,
    .checkbox_container {
        & > div > label {
            display: none;
        }
    }

    .title {
        font-size: 1em!important;
        width: 100%;
        margin-right: 50px!important;

        @include desktop(){
            width: 43%;
        }
    }

    

    .surveyblock {
        @include desktop() {
            display: flex;

            
            .input_container > div > label {
                color: black;
                font-weight: bold;
                font-size: 0.6em;
                width: 125px;
                white-space: nowrap;
            }

            & + .surveyblock {
                .input_container > div > label {
                    color: transparent;
                }
            }

            #ctrl_1176, #ctrl_1179{
                label{
                    color: #000;
                }
            }
        }
    }

    .input_container {
        &.horizontal {
            display: flex;
        }

        & > div {
            width: 83px;
        }
    }

    .widget{
        margin: 0!important;
    }

    .horizontal {
        margin-top: 5px;
    }

    .surveyblock:nth-child(13){
        label{
            color: #000!important;
        }
    }
    .surveyblock:nth-child(14){
        label{
            color: #000!important;
        }
    }
}
