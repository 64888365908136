.iso-collection {
    &__table {
        width: 100%;
        border-spacing: 0;
        border-collapse: collapse;

        th {
            font-family: var(--gotham);
            font-size: rem(18px);
            font-weight: 700;
            line-height: 1.2;
            letter-spacing: 0.03em;
            text-align: left;
            padding: 0;
        }

        th, td {
            padding-left: 0.5em;
            padding-right: 0.5em;
            box-sizing: border-box;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }

            &.iso-collection {
                &__single-price,
                &__product-total,
                &__total-price,
                &__subtotal-price {
                    text-align: right;
                }

                &__amount {
                    text-align: center;
                }

                @include tablet() {
                    &__amount,
                    &__subtotal-label,
                    &__total-label {
                        width: 175px;
                        box-sizing: border-box;
                    }

                    &__total-price {
                        width: 155px;
                        box-sizing: border-box;
                    }
                }
            }
        }

        thead {
            th {
                padding-bottom: rem(15px);
                border-bottom: 1px solid black;
            }
        }

        tbody {
            td {
                padding-top: rem(25px);
                padding-bottom: rem(25px);
                border-bottom: 1px solid black;
            }
        }

        tfoot {
            td {
                padding-top: rem(25px);
                padding-bottom: rem(25px);

                &[class] {
                    border-bottom: 1px solid black;
                }

                &[class*="-label"] {
                    padding-left: 0;
                }
            }
        }

        @include breakpoint(480px, 'max') {
            table, thead, tbody, th, td {
                display: block;
            }

            thead {
                th {
                    &.iso-collection {
                        &__name,
                        &__amount {
                            display: none;
                        }
                    }
                }
            }

            tr {
                display: grid;
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }

            tfoot {
                td {
                    &:nth-child(1),
                    &:nth-child(2) {
                        display: none;
                    }
                }
            }
        }
    }

    &__name {
        a {
            text-decoration: inherit;
            color: inherit;
        }

        &-inside {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @include breakpoint(600px) {
                flex-direction: row;
                align-items: center;
                gap: rem(30px);
            }
        }

        .image_container {
            flex-shrink: 0;
            border-radius: rem(15px);
            overflow: hidden;
            margin-bottom: 1em;

            @include breakpoint(600px) {
                margin-bottom: 0;
            }
        }
    }

    &__product-total,
    &__single-price {
        box-sizing: border-box;
    }

    &__amount {
        text-align: center;

        &-inside {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: rem(5px);
            height: 100%;
        }

        &-input {
            width: rem(75px);
            height: rem(40px);
            border: 1px solid black;
            border-radius: rem(22px);
            box-sizing: border-box;
            text-align: center;
            padding: 0 rem(10px);
        }
    
        &-button {
            width: rem(14px);
            height: rem(23px);
            border: 0;
            padding: 0;
            display: flex;
            background-color: transparent;
            text-align: center;
            justify-content: center;
            align-items: center;
            font-family: var(--work);
            font-size: rem(20px);
            font-weight: 400;
            line-height: 1.2;
            letter-spacing: 0.03em;
            cursor: pointer;
        }
    }

    &__submit-container {
        display: flex;
        justify-content: flex-end;
        margin-top: rem(30px);
        gap: rem(30px);
        flex-wrap: wrap;

        .submit {
            @include button();

            & {
                letter-spacing: 0.1em;
                font-weight: 500;
                width: 100%;
    
                @include tablet() {
                    width: 330px;
                }
            }

            &.button_checkout {
                order: 99;
            }
        }
    }
}
