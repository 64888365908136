$s: 0.7;

.interactivemap {
    margin-top: 2em;
    margin-bottom: 2em;
    margin-left: -36px;
    margin-right: -15px;
    font-size: 0.7em;

    turbo-frame & {
        transition: opacity 0.2s;
    }

    turbo-frame[busy] & {
        opacity: 0.2;
    }

    @include tablet('max') {
        &:last-child {
            margin-bottom: 75vh;
        }
    }

    @include tablet() {
        margin-left: 0;
        margin-right: 0;
    }

    &__general-filter {
        display: none;
        gap: 20px;
        flex-wrap: wrap;
        margin-top: 1em;
        margin-bottom: 1em;
        margin-left: 36px;
        margin-right: 15px;

        @include tablet() {
            margin-left: 0;
            margin-right: 0;
        }

        &-item {
            position: relative;
            user-select: none;

            input {
                position: absolute;
                left: 5px;
                bottom: 5px;
                opacity: 0;
            }

            label {
                display: flex;
                align-items: center;
                font-size: 20px;
                position: relative;
                cursor: pointer;

                &:before {
                    content: 'OFF';
                    display: flex;
                    align-items: center;
                    font-family: var(--gotham);
                    font-size: 12px;
                    font-weight: 500;
                    text-transform: uppercase;
                    margin-right: 10px;
                    border: 0;
                    box-sizing: border-box;
                    width: 60px;
                    height: 30px;
                    background-color: #D9D9D9;
                    transition: background-color 0.2s;
                    border-radius: 15px;
                    overflow: hidden;
                    flex-shrink: 0;
                    padding: 0 4px 0 8px;
                    justify-content: flex-end;
                    color: white;
                }

                &:after {
                    width: 24px;
                    height: 24px;
                    display: block;
                    content: '';
                    border-radius: 12px;
                    position: absolute;
                    left: 3px;
                    top: 3px;
                    bottom: 3px;
                    margin: auto;
                    pointer-events: none;
                    transition: transform 0.2s;
                    background-color: white;
                }
            }

            input:checked ~ label {
                &:before {
                    background-color: var(--light-green);
                    content: 'ON';
                    justify-content: flex-start;
                }

                &:after {
                    transform: translateX(30px)
                }
            }
        }

        &__open-filter {
            width: 30px;
            height: 30px;
            background-color: var(--dark-green);
            border-radius: 15px;
            text-indent: 100px;
            white-space: nowrap;
            overflow: hidden;
            background-image: url('../../images/interactive-map-filter.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 16px auto;
            border: 0;
            padding: 0;
            cursor: pointer;
        }
    }

    &__tooltip {
        display: none;
        position: fixed;
        pointer-events: none;
        font-size: 10px;
        pointer-events: none;
        
        &__inner {
            position: relative;
            margin-left: -50%;
            top: -2.1em;
            background-color: white;
            padding: 1px 2px;
        }

        &.show {
            display: flex;
        }
    }

    &__googlemap {
        height: 75vh;
        max-height: 1000px;

        @include tablet() {
            height: 80vh;
            min-height: 600px;
        }

        .cc-module {
            padding-top: 3em;
        }

        .gm-style-iw-ch {
            display: none !important;
        }

        .gm-style {
            .gm-style-iw {
                width: 89.7435vw !important;
                max-width: (400px * $s) !important;
                box-sizing: border-box;
                border-radius: (40px * $s);
                box-shadow: 0px 0px (40px * $s) 0px #00000026;
                padding: (28px * $s) (30px * $s) !important;
                padding-bottom: (30px * $s) !important;
                font-family: var(--work);
                font-size: (18px * $s);
                font-weight: 400;
                line-height: 1.173;
                letter-spacing: 0.03em;
                text-align: left;
                background-color: white;

                @include tablet() {
                    font-size: (20px * $s);
                    width: (400px * $s) !important;
                }
            }

            button.gm-ui-hover-effect {
                position: absolute !important;
                width: (32px * $s) !important;
                height: (32px * $s) !important;
                border: 0 !important;
                cursor: pointer !important;
                background-image: url('../../images/interactive-map-close.svg') !important;
                background-repeat: no-repeat !important;
                background-position: center !important;
                background-size: (25px * $s) auto !important;
                right: (27.5px * $s) !important;
                top: (27.5px * $s) !important;
                opacity: 1 !important;

                @include tablet('max') {
                    background-size: (25px * $s) auto !important;
                    right: (20px * $s) !important;
                    top: (20px * $s) !important;
                }

                & > span {
                    display: none !important;
                }
            }
        }
    }

    &__popup {
        &__headline {
            font-family: var(--gotham);
            font-size: (20px * $s);
            font-weight: 500;
            line-height: 1.2;
            letter-spacing: 0.03em;
            text-align: left;
            margin-top: 0;
            margin-bottom: (11px * $s);
            margin-right: (26px * $s);

            @include tablet() {
                font-size: (25px * $s);
                margin-bottom: (16px * $s);
                margin-right: (30px * $s);
            }
        }

        &__address {
            margin-top: 0;
            margin-bottom: 0;
        }

        &__buttons {
            display: flex;
            gap: (10px * $s);
            flex-wrap: wrap;
            margin-top: (21px * $s);
            flex-direction: column;

            @include tablet() {
                gap: (20px * $s);
                margin-top: (40px * $s);
                flex-direction: row;
            }
        }

        &__button {
            border: 1px solid var(--dark-green);
            border-radius: (15px * $s);
            background-color: var(--dark-green);
            display: block;
            color: white;
            font-family: var(--gotham);
            font-size: (18px * $s);
            font-weight: 500;
            line-height: (20px * $s);
            letter-spacing: 0.1em;
            text-align: center;
            box-sizing: border-box;
            padding: (4px * $s) (15px * $s);
            width: 100%;
            text-transform: uppercase;
            text-decoration: none;
            transition: background-color 0.2s, border-color 0.2s;
            flex-shrink: 0;

            @include tablet() {
                font-size: (18px * $s);
                width: auto;
                min-width: (150px * $s);
            }

            &:hover {
                color: white;
                background-color: var(--light-green);
                border-color: var(--light-green);
            }

            &:nth-child(even) {
                color: var(--dark-green);
                background-color: transparent;

                &:hover {
                    color: var(--light-green);
                    border-color: var(--light-green);
                }
            }
        }
    }

    &__inner {
        position: relative;
        background-color: white;
        overflow: hidden;
        border-radius: vwmax(40px);
    }

    &__filter-open {
        @include button-reset();
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: (100px * $s);
        height: (100px * $s);
        border-top-left-radius: (50px * $s);
        border-bottom-left-radius: (50px * $s);
        background-color: var(--dark-green);
        text-indent: 150px;
        overflow: hidden;
        white-space: nowrap;
        background-image: url('../../images/interactive-map-filter.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: (50px * $s) auto;
        z-index: 5;
        transition: opacity 0.2s, visibility 0.2s;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
    }

    &.init {
        .interactivemap__filter-open {
            opacity: 1;
            visibility: visible;
            pointer-events: all; 
        }

        .interactivemap__general-filter {
            display: flex;
        }
    }

    &__filter-close {
        @include button-reset();
        width: (48px * $s);
        height: (48px * $s);
        position: absolute;
        right: (23.5px * $s);
        top: (32.39px * $s);
        text-indent: 55px;
        overflow: hidden;
        white-space: nowrap;
        background-image: url('../../images/interactive-map-close.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: (39px * $s) auto;

        @include desktop() {
            right: (34.5px * $s);
            top: (39.39px * $s);
        }
    }

    &__filter-apply {
        @include button();

        & {
            font-weight: 500;
            min-width: (300px * $s);
            line-height: 1.2;
            letter-spacing: 0.1em;
            font-size: (20px * $s);
            font-family: Gotham Rounded;
            margin-top: (40px * $s);
            height: (60px * $s);
            border-radius: (30px * $s);
        }

        @include desktop() {
            height: (60px * $s);
            border-radius: (30px * $s);
        }
    }

    &__filter-container {
        pointer-events: none;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.2s, visibility 0.2s;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        z-index: 1;

        @include desktop() {
            align-items: center;
        }
    }

    &.init.show-filter {
        .interactivemap {
            &__filter-open {
                opacity: 0;
                pointer-events: none;
                visibility: hidden;
            }

            &__filter-container {
                opacity: 1;
                pointer-events: all;
                visibility: visible;
            }
        }
    }

    &__filter {
        position: relative;
        width: (1282px * $s);
        max-width: 89.7435%;
        border-radius: (40px * $s);
        background-color: white;
        box-shadow: 0px 0px (40px * $s) 0px #00000026;
        padding: (30px * $s);
        box-sizing: border-box;
        margin-top: 2em;

        @include desktop() {
            padding: (68px * $s) (70px * $s);
            max-width: (92.8985% * $s);
            margin-top: 0;
        }

        &-headline {
            font-family: var(--gotham);
            font-size: (30px * $s);
            font-weight: 500;
            line-height: 1.2;
            letter-spacing: 0.03em;
            margin-right: (40px * $s);

            @include desktop() {
                font-size: (40px * $s);
                margin-right: (30px * $s);
            }
        }
    }

    &__filter-sections {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: vwmax(80px * $s);
        row-gap: (20px * $s);

        @include desktop() {
            grid-template-columns: repeat(auto-fit, minmax((260px * $s), 1fr));
        }
    }

    &__filter-headline + .interactivemap__filter-sections {
        margin-top: (20px * $s);

        @include desktop() {
            margin-top: (40px * $s);
        }
    }

    &__filter-section {
        display: block;

        &--gemeinden {
            display: none;
        }

        @include desktop() {
            max-width: (330px * $s);
        }

        &-headline {
            font-family: var(--gotham);
            font-size: (25px * $s);
            font-weight: 500;
            line-height: 1.2;
            letter-spacing: 0.03em;
            display: flex;
            align-items: center;
            margin-bottom: (30px * $s);
            margin-top: (10px * $s);

            @include desktop() {
                margin-bottom: 0;
                margin-top: 0;
            }

            figure {
                margin-right: (9px * $s);
                flex-shrink: 0;
            }
        }

        &-categories {
            margin: (20px * $s) 0;
            position: relative;

            &:last-child {
                margin-bottom: 0;
            }

            @include desktop() {
                margin: (39px * $s) 0;
            }

            &-button {
                @include button-reset();
                font-family: var(--work);
                font-size: (20px * $s);
                font-weight: 400;
                line-height: 1.173;
                letter-spacing: 0.03em;
                height: (60px * $s);
                box-sizing: border-box;
                padding: 0 (20px * $s);
                padding-right: (30px * $s);
                display: flex;
                align-items: center;
                border: 1px solid var(--dark-green);
                border-radius: (30px * $s);
                width: 100%;
                display: block;
                position: relative;
                text-align: left;
                transition: border-radius 0.2s, border-color 0.2s;
                z-index: 3;

                &:after {
                    flex-shrink: 0;
                    display: block;
                    position: absolute;
                    right: (21px * $s);
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    width: (16px * $s);
                    height: (29px * $s);
                    background-image: var(--interactive-map-arrow-right);
                    background-repeat: no-repeat;
                    background-position: right;
                    background-size: (16px * $s) auto;
                    content: '';
                    transition: transform 0.2s;
                }
            }

            .items {
                position: absolute;
                left: 0;
                top: (60px * $s);
                right: 0;
                opacity: 0;
                pointer-events: none;
                visibility: hidden;
                transition: opacity 0.2s, visibility 0.2s, border-radius 0.2s, background-color 0.2s;
                border: 1px solid var(--dark-green);
                border-top: 0;
                border-radius: (30px * $s);
                background-color: white;
                z-index: 4;
                max-height: (300px * $s);
                overflow: hidden;
                overflow-y: auto;
                scrollbar-width: thin;

                .item {
                    padding: (12px * $s) (17px * $s);
                    
                    &--sub {
                        padding-left: 1.785em;
                    }

                    &:hover {
                        background-color: #f5f5f5;
                    }
                }
            }

            &.open {
                .interactivemap__filter-section-categories-button {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                    border-bottom-color: black;;

                    &:after {
                        transform: rotate(-90deg);
                    }
                }

                .items {
                    opacity: 1;
                    pointer-events: all;
                    visibility: visible;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                }
            }
        }

        .item {
            position: relative;
            user-select: none;

            input {
                position: absolute;
                left: 5px;
                bottom: 5px;
                opacity: 0;
            }

            label {
                display: flex;
                align-items: center;

                &:before {
                    content: '';
                    display: block;
                    margin-right: (10px * $s);
                    border: 1px solid var(--dark-green);
                    box-sizing: border-box;
                    width: (35px * $s);
                    height: (35px * $s);
                    background-color: white;
                    transition: background-color 0.2s;
                    border-radius: (10px * $s);
                    overflow: hidden;
                    flex-shrink: 0;
                }
            }

            input[type="radio"] ~ label:before {
                border-radius: 50%;
            }

            input:checked ~ label {
                &:before {
                    background-color: var(--dark-green);
                    background-image: var(--interactive-map-checkbox-checked);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: (23px * $s) auto;
                }
            }

            input:checked[type="radio"] + label:before {
                background-image: none;
            }

            @media (pointer:fine) {
                input:active ~ label {
                    &:before {
                        background-color: var(--yet-another-another-light-green);
                    }
                }
            }
        }

        & > .item {
            & + .item {
                margin-top: (40px * $s);
            }
        }

        .interactivemap__filter-section-headline + .item {
            margin-top: (47px + 0.7);
        }

        &--gemeinden {
            & > .item {
                & + .item {
                    @include desktop('max') {
                        margin-top: (13px * $s);
                    }
                }
            }

            .interactivemap__filter-section {
                &-headline {
                    @include desktop('max') {
                        display: none;

                        & + .item {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
}
