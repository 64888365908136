.news-teaser {
    border-radius: rem(30px);
    overflow: hidden;
    border: 0.5px solid black;
    position: relative;
    transition: background-color 0.2s;
    display: block;
    text-decoration: none;

    &:not([data-macy-complete]) {
        margin-bottom: 2em;
    }

    &:hover {
        background-color: var(--yet-another-light-green);
        text-decoration: none;
        color: inherit;
    }

    &__text-content {
        @include pr();
        padding: 0 rem(18px) rem(20px) rem(18px);
        font-size: rem(18px);

        a {
            text-decoration: none;

            &:hover {
                color: inherit;
            }
        }
    }

    &__headline {
        color: var(--dark-green);
        font-family: var(--gotham);
        font-size: 22px;
        font-weight: 700;
        line-height: 1;
        letter-spacing: 0.03em;
        margin: rem(20px) 0;

        a {
            text-decoration: none;
        }

        @include tablet() {
            font-size: 25px;
        }
    }

    &__subheadline {
        font-size: rem(18px);
        letter-spacing: inherit;
        font-weight: inherit;
        font-family: inherit;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__image {
        img {
            width: 100%;
        }
    }

    .combined-teaser {
        position: relative;
        margin: 1em 0;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .combined-teaser .appendix,
    .is-full,
    .is-cancelled {
        position: absolute;
        background-color: var(--yellow);
        color: black;
        font-family: var(--gotham);
        font-weight: 700;
        text-transform: uppercase;
        font-size: rem(22px);
        white-space: nowrap;
        line-height: 1;
        right: 0;
        top: rem(49px);
        border-radius: rem(20px) 0px 0px rem(20px);
        height: rem(40px);
        display: flex;
        align-items: center;
        padding: 0 rem(30px);
        min-width: rem(227px);
        box-sizing: border-box;
        text-align: right;
        justify-content: flex-end;
    }

    .combined-teaser .appendix {
        right: rem(-18px);
        top: 0;
        bottom: 0;
        margin: auto;
    }

    .is-full,
    .is-cancelled {
        display: none;
    }

    .combined-teaser a {
        color: inherit;
        text-decoration: none;
        display: block;

        &:hover {
            color: inherit;
            text-decoration: underline;
        }
    }

    .combined-teasers.combined-teasers--invisible {
        display: none;
    }

    .combined-teasers-load-more-container {
        display: flex;
        justify-content: center;
        margin-top: 1em;
    }

    .combined-teasers-load-more {
        cursor: pointer;
        background-color: transparent;
        border-radius: 50px;
        border: rem(1.2px) solid #241201;
        line-height: rem(24px);
        padding: rem(2.8px) rem(32px);
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        min-width: vwmax(248px);
        color: #241201;
        font-family: var(--gotham);
        font-size: rem(18px);
        font-weight: 700;
        letter-spacing: 0.2em;
        text-decoration: none;
        text-transform: uppercase;
        transition: color 0.2s, border-color 0.2s, background-color 0.2s;

        &:hover {
            background-color: var(--light-green);
            color: white;
            border-color: var(--light-green);
        }
    }
}
