:root {
    --dark-green: #006930;
    --light-green: #95C11F;
    --another-light-green: #cae08f;
    --lighter-green: #f4f9e9;
    --yet-another-light-green: #f2f7f5;
    --yet-another-another-light-green: #cce1d6;

    --black: black;
    --almost-black: #030303;
    --not-quite-black: #241201;

    --text-color: var(--black);

    --dark-purple: #884A5F;
    --light-purple: #D789A5;
    --another-light-purple: #d789a5;
    --another-lighter-purple: #f0d3de;
    --another-another-lighter-purple: #f4dee6;
    --lighter-purple: #f3dce4;
    --even-lighter-purple: #fbf3f6;
    --another-another-another-lighter-purple: #f3edef;
    --another-another-another-another-lighter-purple: #f9edf2;

    --yellow: #FC0;
    --yellow-dark: #b69c1b;
    --yellow-light: #e9c427;
    --yellow-lighter: #fbf3d7;

    --mint: #4a946c;
    --light-mint: #b8d5c5;
    --another-light-mint: #cfe2d8;
    --another-another-light-mint: #daeae1;
    --lighter-mint: #e6f0ea;
    --another-lighter-mint: #dae9e1;
    --another-another-lighter-mint: #f2f8f5;
    --another-another-ligher-mint: #f5f9f7;

    --button-background-color: var(--dark-green);
    --button-text-color: white;
    --button-border-color: var(--dark-green);

    --button-hover-background-color: var(--button-text-color);
    --button-hover-text-color: var(--button-background-color);
    --button-hover-border-color: var(--button-border-color);
}
