@use 'sass:math';

.mod_article {
    &.article--columns {
        margin-top: rem(60px);
        margin-bottom: rem(60px);

        @for $i from 1 through 12 {
            &--#{$i} {
                & > .inside {
                    display: grid;
                    grid-template-columns: 1fr;
                    column-gap: 30px;
                    row-gap: 40px;

                    @include tablet() {
                        grid-template-columns: repeat(#{math.ceil(math.div($i, 2))}, 1fr);
                        row-gap: 70px;
                    }

                    @include larger() {
                        grid-template-columns: repeat(#{$i}, 1fr);
                    }
                }
            }
        }
    }

    &.article--box-links > .inside {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 30px;
        row-gap: 14px;

        @include tablet() {
            grid-template-columns: repeat(2, 1fr);
            row-gap: 30px;
        }

        @include larger() {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &.article--background {
        @include fwbg();
        padding-top: 1px;
        padding-bottom: 1px;

        &--light-green {
            &:before {
                background-color: var(--lighter-green);
            }
        }

        &--pattern {
            &:before {
                background-image: url('../../images/background.svg');
                background-position: center center;
                opacity: 0.8;

                @include tablet() {
                    opacity: 1;
                }
            }
        }
    }
}
