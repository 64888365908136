.ce_rsce_magazin {
    margin-bottom: 34px;
    padding: 5px;
    border: solid rgba(6, 7, 7, 0.2) 1px;
    display: flex;
    flex-direction: column;

    h2,
    h3 {
        margin: 0;
    }

    img {
        margin: 0 auto;
    }

    .magazin__text {
        padding: 0;
        display: block;
        text-align: center;

        h2 {
            margin-bottom: 5px;
            font-family: var(--gotham);
            font-size: 25px;
            font-weight: bold;
            color: var(--dark-green);
        }

        h3 {
            font-family: var(--thirsty);
            font-size: 40px;
            font-weight: 700;
            color: var(--light-green);
        }

        p {
            letter-spacing: .55px;
            margin-top: 16px;
            line-height: 1.25;
            width: 197px;
            text-align: left;
            margin-left: auto;
            margin-right: auto;
            padding: 0;
            font-family: var(--work);
            font-size: 18px;
        }
    }

    a.link {
        text-align: center;
        margin: 27px auto 15px auto;
        line-height: 1.1;
        font-family: var(--gotham);
        font-weight: bold;
        text-transform: uppercase;
        padding: 32px 0 27px 21px;
        background-color: rgba(170, 202, 58, .8);
        border-radius: 10px;
        color: #fff;
        display: block;
        text-decoration: none;
        box-sizing: border-box;
        padding-right: 16px;
        max-width: 296px;
        z-index: 1;
        position: relative;

        &:hover {
            color: #fff;
        }
    }

    @include tablet() {
        padding: 10px;

        .magazin__image {
            min-width: 300px;
        }
    }

    @include desktop() {
        flex-direction: row;

        a.link {
            font-size: 2.5vh;
            text-align: left;
            margin-left: -15px;
        }

        .magazin__text {
            width: 50%;
        }
    }

    @include wide() {
        padding: 17px 20px 17px 25px;

        a.link {
            font-size: 35px;
        }

        h2 {
            padding-right: 10px;
        }

        h3 {
            padding-right: 35px;
        }
    }
}
