.swiper, .swiper-container {

    .swiper-button-prev,
    .swiper-button-next {
        background-image: url('../../images/swiper-arrow.svg');
        background-size: cover;
    }

    .swiper-button-prev {
        transform: rotate(180deg);
    }

    .swiper {
        &-slide {
            img {
                width: 100%;
            }
        }
    }
}
