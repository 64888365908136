.sidebar-element,
.ce_rsce_sidebar {
    overflow: hidden;
    background-color: var(--lighter-green);
    margin-bottom: rem(50px);
    border-radius: rem(40px);
    overflow: hidden;
    text-align: center;
    font-size: rem(18px);

    &.logos {
        img {
            width: 85%;
            height: auto;
        }

        p {
            text-align: center;
        }
    }

    iframe.google-map,
    div.google-map {
        width: 100%;
        height: 230px;
        display: block;
    }

    div.google-map--empty {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    h2 {
        @include h2-side();
    }

    &.yellow {
        h2 {
            background-color: var(--yellow-light);
        }
    }

    &.gray {
        h2 {
            background-color: var(--dark-green);
        }
    }

    &.rose-light {
        h2 {
            background-color: var(--light-purple);
        }
    }

    &.rose-dark {
        background-color: var(--another-another-another-lighter-purple);

        h2 {
            background-color: var(--dark-purple);
        }
    }

    &:not(.sidebar-element--rounded-image) {
        .sidebar-element__image {
            img {
                filter: grayscale(100%) saturate(100%) brightness(0);
            }
        }
    }
}


.sidebar-element {
    &__image {
        margin-bottom: rem(30px);

        img {
            margin: auto;
        }
    }

    &--rounded-image {
        .sidebar-element__image {
            border-radius: rem(30px);
            overflow: hidden;
            display: inline-block;
            margin-bottom: rem(10px);
        }
    }

    &__content {
        @include pr();
        padding: rem(26px) rem(30px);
        line-height: 1.3;
    }

    &__more {
        display: flex;
        justify-content: center;
    }

    &__elements {
        display: flex;
        flex-direction: column;
        gap: rem(30px);
    }

    &__element {
        width: 100%;

        &-image {
            margin-bottom: rem(30px);

            img {
                margin: auto;
            }
        }

        &-text {
            @include pr();
        }

        &-link {
            margin-top: rem(26px);
            display: flex;
            justify-content: center;
        }
    }

    &__element-button,
    &__more a {
        border-radius: 24px;
        border: rem(1.2px) solid #241201;
        line-height: rem(20px);
        padding: rem(2.8px) rem(14px);
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        min-width: vwmax(211px);
        color: #241201;
        font-family: var(--gotham);
        font-size: rem(16px);
        font-weight: 700;
        letter-spacing: 0.2em;
        text-decoration: none;
        text-transform: uppercase;
        transition: color 0.2s, border-color 0.2s, background-color 0.2s;

        &:hover {
            background-color: var(--light-green);
            color: white;
            border-color: var(--light-green);

            .gray & {
                background-color: var(--dark-green);
                border-color: var(--dark-green);
            }
            
            .yellow & {
                background-color: var(--yellow-light);
                border-color: var(--yellow-light);
            }

            .rose-light & {
                background-color: var(--light-purple);
                border-color: var(--light-purple);
            }
        
            .rose-dark & {
                background-color: var(--dark-purple);
                border-color: var(--dark-purple);
            }
        }
    }

    &__social-media {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: rem(10px);
        margin-top: rem(22px);

        &-element {
            flex-shrink: 0;
        }
    }
}

//Element specific styles, inherited from old NiG
.ringback-sidebar {

    div.content {
        padding-top: 4px;
    }

    p {
        white-space: nowrap;
    }

    p.more {
        margin-top: 27px;
    }

    .yellow-text,
    .bold {
        font-family: var(--gotham);
        font-weight: 700;
    }

    img {
        margin-top: 40px;
    }

    .telefon {
        display: block;
        margin-left: 16%;
        margin-bottom: 3px;
    }

    .yellow-text {
        //color: $yellow-dark;
        font-size: 25px;
        white-space: nowrap;
        margin-left: 11px;
    }

    .ringback-time {
        font-weight: 300;
        font-size: 22px;
        display: block;
        margin-top: 10px;
    }

    .bigger-font {
        font-size: 24px;
        margin-bottom: 2px;
        display: inline-block;
    }
}

/* Strong garten telefon */
strong.strong-bigger-font {
    font-size: 25px !important;
    line-height: 38px;
}

.no-storno{
    .sidebar-storno{
        display: none;
    }
}

.no-hinweis{
    .sidebar-hinweis{
        display: none;
    }
}

.no-top-margin{
    margin-top: 0;
}