ul.list--timetable {
    margin: rem(48px) 0;
    padding: 0;
    list-style: none;

    li {
        padding: rem(8px) rem(23px);
        border-radius: rem(40px);
        background-color: white;
        overflow: hidden;
        line-height: 1.3333;

        & + li {
            margin-top: rem(48px);
        }
    }

    strong {
        font-weight: 700;
    }
}
