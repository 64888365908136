#container {
    @include main-padding();
    max-width: var(--max-width);
    margin: 0 auto;
    margin-top: 110px;

    @include menu() {
        margin-top: 0;
    }
}
