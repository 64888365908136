.newsletter {
    margin: 38px 0;
    border-radius: 15px;
    transition: background-color 0.2s;
    overflow: hidden;

    @include tablet() {
        margin: 90px 0;
        border-radius: 50px;
    }

    @media only print {
        display: none !important;
    }

    &__headline-container {
        display: flex;
        justify-content: center;
    }

    &__button {
        --button-text-color: var(--dark-purple);
        --button-background-color: var(--lighter-purple);
        --button-border-color: var(--lighter-purple);
        --button-hover-background-color: var(--another-light-purple);
        --button-hover-text-color: white;
        --button-hover-border-color: var(--lighter-purple);
        @include button();
        border: none;
        box-sizing: border-box;
        letter-spacing: 0.1em;
        width: 100%;

        @include tablet() {
            width: auto;
            font-size: 22px;
            min-width: vwmax(680px);
        }
    }

    &__headline {
        color: inherit;
        font-size: inherit;
        letter-spacing: inherit;
        margin: 0;
    }

    &__content {
        display: none;
        padding: 0 1em 1em 1em;

        @include tablet() {
            padding: 0 42px 42px 42px;
        }
    }

    &__image {
        img {
            width: 100%;
        }
    }

    &.show {
        background-color: var(--even-lighter-purple);

        .newsletter {
            &__content {
                display: block;
            }
        }
    }

    &__selection {
        &-elements {
            display: grid;
            grid-template-columns: 1fr;
            row-gap: rem(45px);

            @include desktop() {
                grid-template-columns: 1fr 1fr 1fr;
                column-gap: vwmax(45px);
            }
        }

        &-option {
            min-width: 0;
            max-width: none;
            cursor: pointer;

            .headline {
                color: #000;
                font-family: var(--gotham);
                font-weight: 700;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: rem(28px);

                &:before {
                    content: '';
                    display: block;
                    width: rem(35px);
                    height: rem(35px);
                    border-radius: rem(8px);
                    border: 1px solid var(--black);
                    box-sizing: border-box;
                    margin-right: rem(22px);
                    flex-shrink: 0;
                }
            }

            &.selected {
                .headline {
                    &:before {
                        @include sprite('../../images/newsletter-selected.svg');
                    }
                }
            }

            .content {
                overflow: hidden;
                border-radius: vwmax(40px);
                background-color: white;
            }

            .description {
                padding: 36px 44px;
                text-align: center;
                letter-spacing: 0.1em;
            }
        }

        &-text {
            text-align: center;
            margin: 0 auto;
            margin-top: rem(48px);
            margin-bottom: rem(70px);
            max-width: 1167px;
        }
    }

    &__module {
        display: none;

        @include tablet() {
            padding: 0 rem(47px);
            padding-bottom: rem(58px);
        }

        &-title {
            color: var(--dark-purple);
            font-family: var(--gotham);
            font-size: rem(30px);
            font-weight: 700;
            letter-spacing: 0.1em;
            margin-top: rem(80px);
            margin-bottom: rem(38px);
            text-transform: uppercase;
        }

        .formbody {
            @include desktop() {
                display: grid;
                grid-template-columns: 3fr 2fr;
                column-gap: 2em;
            }

            @include larger() {
                grid-template-columns: minmax(0, vwmax(800px)) minmax(0, 1fr);
                column-gap: vwmax(120px);
            }
        }

        .widget {
            @include desktop() {
                grid-column: 1 / 2;
            }

            &-submit {
                margin-top: rem(38px);
                display: flex;
                justify-content: center;
            }

            &-explanation {
                @include pr();
            }

            &--70d4a448f8 {
                display: none;
            }

            &--84f3c7e3a3 {
                grid-column: 2 / 3;
                grid-row: 1 / span 8;
            }

            &--SALTUATION {
                & > .radio_container {
                    & > legend {
                        display: none;
                    }

                    padding-left: 0;
                    padding-right: 0;
                }
            }

            &--74ad2418d0 {
                padding-right: 0;

                & > fieldset {
                    & > legend {
                        margin-bottom: rem(36px);
                    }
                }
            }

            &--84f3c7e3a3 {
                & > fieldset {
                    & > legend {
                        font-weight: bold;
                        margin-bottom: 2em;
                    }
                }
            }

            &--gdpr {
                font-size: rem(15px);
            }

            &--mandatory {
                height: 0;
                margin-top: rem(-14px);
                font-size: rem(24px);
            }

            &-submit {
                grid-column: 1 / -1;
            }
        }

        [type="submit"] {
            --button-text-color: var(--dark-purple);
            --button-background-color: var(--another-lighter-purple);
            @include button();
            border: 0;
            box-sizing: border-box;
            min-width: vwmax(882px);
        }
    }

    &__text {
        @include pr();
        display: none;
        padding: 0 rem(47px);
        padding-top: rem(58px);
        padding-bottom: rem(58px);
    }

    &.show-all {
        .newsletter {
            &__module {
                display: block;
            }
        }
    }

    &.submitted {
        .newsletter {
            &__text {
                display: block;
            }

            &__module {
                display: none;
            }
        }
    }
}
