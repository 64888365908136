.layout_full {
    & > h1 + .ce_rs_columns_start {
        & > .rs-columns {
            & > .ce_rs_column_start:first-child {
                & > .inside {
                    & > .ce_image:first-child {
                        figure {
                            border-radius: rem(40px);
                            overflow: hidden;

                            img {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    .ce_form{
        margin-bottom: 2em;
    }

    &.no-date{
         p.info{
            display: none;
         }
    }

    .subheadline {
        color: #000;
        font-family: var(--gotham);
        font-size: rem(28px);
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        letter-spacing: 0.05em;
        margin-top: 0;
        margin-bottom: 1em;
    }

    .registration {
        margin: rem(78px) 0;
        padding: rem(44px);
        display: none;
        background-color: var(--lighter-green);
        border-radius: rem(50px);
        overflow: hidden;

        &.open {
            display: block;
        }

        .formbody {
            @include tablet() {
                grid-template-columns: 1fr 1fr;

                .widget {
                    grid-column: 1 / -1;

                    &-text,
                    &-select {
                        grid-column: auto / span 1;
                    }
                }
            }
        }
    }

    h1 {
        display: flex;
        align-items: center;
        gap: 0.25em;

        .detail-tag {
            background-color: var(--yellow);
            color: black;
            font-family: var(--gotham);
            font-weight: 700;
            text-transform: uppercase;
            font-size: rem(22px);
            white-space: nowrap;
            line-height: 1;
            border-radius: 0 rem(20px) rem(20px) 0;
            height: rem(40px);
            padding: 0 rem(30px);
            min-width: rem(227px);
            box-sizing: border-box;
            text-align: left;
            display: inline-flex;
            align-items: center;
        }
    }
}

.register-button {
    @include button();

    & {
        height: rem(60px);
        box-sizing: border-box;
        font-size: rem(18px);
        letter-spacing: 0.2em;
        text-transform: uppercase;
        position: relative;
        margin-bottom: 2rem;
    }

    a {
        text-decoration: none;
    }

    @include tablet() {
        height: rem(60px);
        font-size: rem(18px);
    }
}

.news-buttons {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    gap: 19px;
    --button-border-color: var(--dark-green);
    --button-text-color: var(--dark-green);
    --button-background-color: white;
    --button-hover-background-color: var(--button-text-color);
    --button-hover-text-color: var(--button-background-color);
    --button-hover-border-color: var(--button-border-color);

    @media only print {
        display: none !important;
    }

    .more-button {
        @include button();

        & {
            height: rem(60px);
            box-sizing: border-box;
            font-size: rem(18px);
            letter-spacing: 0.2em;
            text-transform: uppercase;
            position: relative;
        }

        &.open-registration,
        &.webinar {
            --button-border-color: var(--dark-purple);
            --button-text-color: white;
            --button-background-color: var(--dark-purple);
            --button-hover-background-color: var(--button-text-color);
            --button-hover-text-color: var(--button-background-color);
            --button-hover-border-color: var(--button-border-color);
        }

        @include tablet() {
            height: rem(60px);
            font-size: rem(18px);
        }
    }

    .addtocal {
        position: relative;
        --button-hover-border-color: var(--dark-green);
        --button-hover-text-color: white;
        --button-hover-background-color: var(--dark-green);

        .more-button {
            z-index: 4;
        }

        ul.options {
            @include listreset();
            position: absolute;
            top: rem(32.5px);
            padding-top: calc(rem(32.5px) + 0.3em);
            padding-bottom: 0.4em;
            left: 0;
            right: 0;
            background-color: var(--lighter-green);
            opacity: 0;
            visibility: hidden;
            transition: 0.4s;
            z-index: 3;
            border-radius: 0 0 rem(32.5px) rem(32.5px);
            overflow: hidden;

            a {
                display: block;
                line-height: rem(16px);
                font-size: rem(16px);
                padding: rem(7px);
                text-align: center;
                text-decoration: none;

                &:hover {
                    color: inherit;
                    //background-color: $green-02;
                }
            }
        }

        &:hover {
            ul.options {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.schaugarten-full {
    &__sg-des-jahres-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 2.5rem;
    }
}
.event_host_detail{
    position: relative;
    font-size: inherit;
    color: var(--not-quite-black);
    font-family: var(--gotham);
    line-height: normal;
    letter-spacing: 0.1em;
    margin: 2.5rem 0;
    background-color: var(--light-mint);
    border: 1px solid var(--light-mint);
    box-sizing: border-box;
    min-height: rem(60px);
    align-items: center;
    border-radius: rem(30px);
    padding: 0.1em rem(17px);
    text-transform: none !important;
    display: flex;
    align-items: center;
}