.nig-styled {
    color: var(--dark-green);
    font-family: var(--thirsty);
    font-size: 23px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: none;

    @include tablet() {
        font-size: 60px;
    }
}
