.general-teaser {
    display: flex;
    flex-direction: column;
    min-width: 0;
    max-width: none;

    &__subheadline {
        font-family: var(--work);
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.1em;
        margin: 0 12px;
        margin-bottom: 14px;
        text-transform: uppercase;
        min-height: 2.333em;
        display: flex;
        align-items: flex-end;

        @include tablet() {
            font-size: 20px;
            margin: 0 28px;
            margin-bottom: 14px;
        }
    }

    &__image {
        border-radius: 20px;
        overflow: hidden;

        @include tablet() {
            border-radius: 30px;
        }

        &--below {
            order: 99;
            margin-top: 14px;

            @include tablet() {
                margin-top: 28px;
            }
        }

        img,
        iframe {
            transition: transform 0.8s;
            width: 100%;
        }
    }

    &:hover {
        .general-teaser__image {
            img,
            iframe {
                transform: scale(1.05);
            }
        }
    }

    &__headline {
        color: var(--dark-green);
        font-family: var(--gotham);
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.03em;
        margin: 14px 12px;
        text-transform: none;
        line-height: 1;

        @include tablet() {
            font-size: 28px;
            margin: 0 28px;
            margin-top: 30px;
            margin-bottom: 24px;
        }
    }

    &--float-below {
        .general-teaser__headline {
            margin-top: 0;
        }
    }

    &__text {
        @include pr();
        font-size: 12px;
        margin: 0 14px;

        @include tablet() {
            margin: 0 28px;
            font-size: 20px;
        }
    }

    &--iframe {
        .general-teaser {
            &__image {
                &--iframe {
                    width: 100%;
                    padding-top: 80.9090%;
                    position: relative;

                    & > iframe {
                        position: absolute;
                        left: 0;
                        top: -0.8264%;
                        width: 100%;
                        height: 101.6528%;
                    }
                }
            }
        }
    }
}

.rs-column > .inside > .general-teaser + .general-teaser {
    margin-top: vwmax($column-gutter);
}
