.ce_rsce_countdown {
    background-color: var(--dark-green);
    padding: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
    border-radius: rem(50px);
    
    .label {
        margin-top: 5px;
        margin-bottom: 15px;
        font-family: var(--gotham);
        font-weight: 700;
        line-height: 1.25;
        color: #ffffff;
        font-size: 20px;

        span {
            font-family: var(--thirsty);
            color: var(--light-green);
            font-weight: 800;
            line-height: 1;
            color: var(--light-green);
            font-size: 25px;
        }
    }

    .countdown {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 25px 0px 28px 0px;
        position: relative;

        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            height: 2px;
            background-color: var(--light-green);
        }

        &:before {
            top: 0px;
        }

        &:after {
            bottom: 0px;
        }

        div:first-child,
        div:nth-child(2) {
            margin-bottom: 20px;
        }

        &>div {
            background-color: var(--light-green);
            border-radius: 10px;
            width: 48%;

            p:first-child {
                margin-top: 18px;
                margin-bottom: 0px;
                line-height: 37px;
                font-size: 50px;
            }

            p:last-child {
                margin-top: 9px;
                margin-bottom: 16px;
                line-height: 14px;
                font-size: 20px;
            }

            p {
                text-transform: uppercase;
                font-family: var(--gotham);
                font-weight: 700;
                color: #fff;
                text-align: center;
            }
        }
    }

    @include desktop() {
        padding: 32px 32px 51px 32px;
        margin-top: 0px;

        .label {
            margin-top: 0;
            margin-bottom: 26px;
            font-size: 40px;

            span {
                font-size: 50px;

            }
        }

        .countdown {

            div:first-child,
            div:nth-child(2) {
                margin-bottom: 0px;
            }

            &>div {
                width: 23.5%;
            }
        }
    }
}
