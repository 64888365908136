@mixin h1() {
    color: var(--black);
    font-family: var(--gotham);
    font-size: rem(35px);
    font-style: normal;
    font-weight: 700;
    line-height: 121.5%;
    margin: rem(50px) 0;
    border-bottom: 1px solid var(--black);
    text-transform: none !important;
    padding-bottom: rem(10px);
    margin-top: rem(42px);
}

@mixin h2() {
    color: var(--black);
    font-family: var(--gotham);
    font-size: rem(30px);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: rem(46px);
    margin-bottom: 1rem;
}

@mixin h2-side() {
    border-radius: 20px 20px 0 0;
    margin: 0;
    text-align: center;
    color: white;
    font-family: var(--gotham);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    padding: 15px;
    background-color: var(--light-green);

    @include tablet() {
        border-radius: vwmax(40px) vwmax(40px) 0 0;
        font-size: vwmax(22px);
        padding: vwmax(17.5px);
    }
}

@mixin h3() {
    color: var(--black);
    font-family: var(--gotham);
    font-size: rem(22px);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.1em;
}

@mixin h4() {
    font-weight: 700;
    font-family: var(--work);
    font-size: rem(20px);
}

@mixin h5() {

}

@mixin h6() {

}

@mixin blocked-headline() {
    margin: 0;
    font-size: 24px;
    letter-spacing: 0.19px;
    line-height: 27.5px;
    background-color: white;
    padding: 14px;
    text-transform: uppercase;
    font-family: var(--font-cond);
    font-weight: 950;
    text-align: center;

    @include tablet() {
        font-size: 33px;
        letter-spacing: 0.26px;
        line-height: 40px;
        padding: 15px 65px;
        text-align: left;
    }
}

@mixin typo() {
    line-height: 1;
}

@mixin margin-type($type, $margin, $important: false) {
    @if $margin > 1 {
        @if $important {
            margin-#{$type}: #{$margin * 0.5}rem !important;

            @include tablet() {
                margin-#{$type}: #{$margin * 0.75}rem !important;
            }

            @include desktop() {
                margin-#{$type}: #{$margin}rem !important;
            }
        } @else {
            margin-#{$type}: #{$margin * 0.5}rem;

            @include tablet() {
                margin-#{$type}: #{$margin * 0.75}rem;
            }

            @include desktop() {
                margin-#{$type}: #{$margin}rem;
            }
        }
    } @else {
        @if $important {
            margin-#{$type}: #{$margin}rem !important;
        } @else {
            margin-#{$type}: #{$margin}rem;
        }
    }
}

@mixin checker() {
    background-image: linear-gradient(45deg, #808080 25%, transparent 25%), linear-gradient(-45deg, #808080 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #808080 75%), linear-gradient(-45deg, transparent 75%, #808080 75%);
    background-size: 10px 10px;
    background-position: 0 0, 0 5px, 5px -5px, -5px 0px;
}

/* paragraph, ul reset */
@mixin pr($headlines: false) {
    p, ul {
        line-height: inherit;

        &:first-child {
            margin-top: 0;
        }
    
        &:last-child {
            margin-bottom: 0;
        }
    }

    @if $headlines {
        h1, h2, h3, h4, h5, h6 {
            line-height: inherit;

            &:first-child {
                margin-top: 0;
            }
        
            &:last-child {
                margin-bottom: 0;
            } 
        }
    }
}

@mixin button() {
    border: 1px solid var(--button-border-color);
    background-color: var(--button-background-color);
    color: var(--button-text-color);
    text-align: center;
    font-family: var(--gotham);
    font-size: 9px;
    font-weight: 700;
    letter-spacing: 0.2em;
    padding: 0;
    margin: 0;
    border-radius: 100px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    text-decoration: none;
    padding: 0 12px;
    cursor: pointer;
    transition: background-color 0.2s, border-color 0.2s, color 0.2s;
    box-sizing: border-box;

    &:hover {
        border-color: var(--button-hover-border-color);
        background-color: var(--button-hover-background-color);
        color: var(--button-hover-text-color);
        text-decoration: none;
    }

    @include tablet() {
        font-size: 20px;
        height: 60px;
        padding: 0 45px;
    }
}

@mixin main-padding() {
    padding-left: 36px;
    padding-right: 15px;

    @include tablet() {
        padding-left: vwmax(172px);
        padding-right: vwmax(172px);
    }
}

@mixin button-reset() {
    padding: 0;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    border-radius: 0;
}
