.blog-latest {
    &__image {
        border-radius: vw(20px);
        overflow: hidden;

        @include desktop() {
            border-radius: 30px;
        }

        img {
            transition: transform 0.8s;
            width: 100%;
        }
    }

    &:hover {
        .blog-latest__image {
            img {
                transform: scale(1.05);
            }
        }
    }

    &__author {
        margin-top: vw(-31px);
        display: flex;

        @include desktop() {
            margin-top: -50px;
        }

        &-image {
            flex-shrink: 0;
            border-radius: vw(15px);
            border: vw(1px) solid black;
            overflow: hidden;
            margin-right: vw(11px);

            img {
                width: vw(78px);
            }

            @include desktop() {
                border-radius: 15px;
                border: 1px solid black;
                margin-left: 30px;
                margin-right: 20px;

                img {
                    width: auto;
                }
            }
        }

        &-name {
            margin-top: vw(38px);
            font-size: vw(12px);
            font-weight: 300;

            @include desktop() {
                margin-top: 68px;
                font-size: 18px;
            }
        }
    }

    &__headline {
        margin-top: 16px;
        color: var(--dark-green);
        font-family: var(--gotham);
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.03em;
        margin-bottom: 0;

        @include desktop() {
            margin-top: 30px;
            font-size: 30px;
            margin-left: 30px;
        }
    }

    &__subheadline {
        margin-top: 9px;
        margin-bottom: 0;
        font-size: 12px;
        letter-spacing: 0.03em;

        @include desktop() {
            margin-top: 18px;
            font-size: 18px;
            margin-left: 30px;
        }
    }
}
