.ce_accordionSingle {
    figure.image_container {
        img {
            overflow: hidden;
            border-radius: rem(50px);
            display: block;
        }
    }

    &:not(.image-original-size) {
        figure.image_container {   
            img {
                width: 100%;
            }
        }
    }
}
