@use 'sass:math';

.ce_gallery {
    ul {
        list-style: none;
        margin: 2em 0;
        padding: 0;
        display: grid;
        gap: rem(23px);
        grid-template-columns: 1fr;

        @for $i from 1 through 12 {
            &.cols_#{$i} {
                @include tablet() {
                    grid-template-columns: repeat(#{math.ceil(math.div($i, 2))}, 1fr);
                }

                @include desktop() {
                    grid-template-columns: repeat(#{$i}, 1fr);
                }
            }
        }
    }

    figure {
        border-radius: rem(30px);
        overflow: hidden;

        img {
            width: 100%;
        }
    }
}
