.ce_table {
    --table-row-odd: transparent;
    margin: 1em 0;

    &.table--pink {
        --table-row-odd: var(--light-mint);
    }

    table {
        margin: 1em 0;
        width: 100%;
        border: none;
        border-collapse: collapse;
    }

    tr.odd {
        background-color: var(--table-row-odd);
    }

    td,
    th {
        line-height: 1.39;
        letter-spacing: normal;
        text-align: left;
        padding-right: 15px;

        &:last-child {
            padding-right: 0;
        }

        @include tablet() {
            padding-right: 30px;
        }
    }

    td {
        vertical-align: top;
        font-weight: normal;
    }

    th {
        white-space: nowrap;
        font-weight: 600;
    }

    thead {
        position: relative;

        &:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -5px;
            height: 1px;
            background-color: rgba(0, 0, 0, .3);
        }
    }
}
