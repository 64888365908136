.mod_iso_productlist {
    .product_list {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(min(rem(260px), rem(320px)), 1fr));
        gap: rem(34px);
    }

    .product {
        border: 1px solid black;
        border-radius: rem(40px);
        overflow: hidden;

        .image_container {
            img {
                width: 100%;
            }
        }

        .content {
            margin: rem(20px);
        }

        [itemprop="name"] {
            font-family: var(--gotham);
            font-size: rem(22px);
            font-weight: 700;
            line-height: 1.2;
            letter-spacing: 0.03em;
            margin: 0;
            margin-bottom: rem(49px);

            &:last-child {
                margin-bottom: 0;
            }
        }

        .add_to_cart,
        .details > a {
            height: rem(30px);
            border-radius: rem(15px);
            overflow: hidden;
            display: block;
            cursor: pointer;
            background-color: transparent;
            font-family: var(--gotham);
            font-size: rem(18px);
            font-weight: 500;
            line-height: 1.2;
            letter-spacing: 0.1em;
            text-align: center;
            text-transform: uppercase;
            padding: rem(3.2px) rem(10px) rem(5.2px);
            text-align: center;
            text-decoration: none;
            width: 100%;
            border: 1px solid var(--dark-green);
            box-sizing: border-box;
            transition: color 0.2s, border-color 0.2s, background-color 0.2s;
        }

        .add_to_cart {
            background-color: var(--dark-green);
            color: white;

            &:hover {
                border-color: var(--light-green);
                background-color: var(--light-green);
            }
        }

        .details > a {
            margin-top: rem(10px);
            color: var(--dark-green);
            border-color: var(--dark-green);

            &:hover {
                border-color: var(--light-green);
                color: var(--light-green);
            }
        }
    }
}
