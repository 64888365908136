.download-element,
.ce_list > ul > li,
.ce_list > ol > li {
    list-style: none;
    margin: 0;
    padding: 0;
    background-color: var(--another-another-ligher-mint);
    border-radius: rem(20px);
    font-size: rem(20px);
    line-height: rem(24px);
    padding: rem(8px) rem(31.5px);
    padding-right: rem(20px);
    overflow: hidden;
    line-height: normal;
    position: relative;

    &:before {
        width: rem(4.5px);
        height: rem(4.5px);
        border-radius: rem(4.5px);
        background-color: var(--dark-green);
        position: absolute;
        left: rem(13.5px);
        top: 0;
        bottom: 0;
        margin: auto;
        content: '';
        display: block;
    }

    &:nth-child(even) {
        background-color: transparent;
    }

    a {
        text-decoration: none;

        &:hover {
            color: var(--dark-green);
            text-decoration: none;
        }
    }
}
