.ce_text {
    line-height: 1.5;

    figure.image_container {
        img {
            overflow: hidden;
            border-radius: rem(50px);
            display: block;
        }
    }

    &:not(.image-original-size) {
        figure.image_container {   
            img {
                width: 100%;
            }
        }
    }

    &.text--highlight {
        @include pr(true);
        border-radius: rem(30px);
        overflow: hidden;
        background-color: var(--lighter-green);
        margin: rem(100px) 0;
        padding: rem(22px) rem(21px);

        &--mint {
            background-color: var(--lighter-mint);
        }

        h2:first-child {
            margin-top: rem(32px);
            margin-bottom: rem(50px);
        }

        figure.image_container.float_above {
            margin-bottom: rem(64px);

            & ~ p:last-child {
                margin-bottom: rem(44px);
            }
        }

        ul.list--timetable:last-child {
            margin-bottom: rem(48px);
        }
    }

    &.text--person {
        @include pr();
        border-radius: rem(30px);
        overflow: hidden;
        background-color: var(--lighter-green);
        padding: rem(32px) rem(34px);
        display: flex;
        gap: rem(27px);

        .text--person__image {
            flex-shrink: 0;
            align-self: flex-start;
            border-radius: rem(20px);
        }
    }

    &.text--recipe {
        padding: rem(12px) rem(37px);
        overflow: hidden;
        border-radius: rem(40px);
        background-color: var(--another-another-another-another-lighter-purple);

        & > h1,
        & > h2,
        & > h3,
        & > h4, 
        & > h5,
        & > h6 {
            &:first-child {
                font-family: var(--thirsty);
                font-size: rem(70px);
                font-weight: 600;
                line-height: 1;
                text-align: left;
                color: #e8d5dc;
                margin-top: rem(20px);
                margin-bottom: -0.75em;
            }
        }
    }
}
