.nig25list {
    margin-top: rem(60px);
    margin-bottom: rem(60px);

    &__entries {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 30px;
        row-gap: 40px;

        @include tablet() {
            grid-template-columns: repeat(2, 1fr);
            row-gap: 70px;
        }

        @include larger() {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &__entry {
        max-height: none;
        min-height: 0;
        align-self: flex-start;

        &--video {
            .nig25list {
                &__image {
                    &:before {
                        content: '';
                        display: block;
                        mask-image: var(--play);
                        background-color: white;
                        mask-size: 100% auto;
                        mask-repeat: no-repeat;
                        mask-position: center;
                        width: 64px;
                        height: 64px;
                        pointer-events: none;
                        z-index: 1;
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                    }
                }
            }
        }
    }

    &__image {
        position: relative;

        &:after {
            @include sprite('../../images/nig25_smaller.png');
            width: 80px;
            height: 66px;
            content: '';
            display: block;
            position: absolute;
            right: 30px;
            bottom: 30px;
            pointer-events: none;
        }
    }

    &__text {
        margin-top: 1em;
    }
}
