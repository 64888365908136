.timeline {
    margin: rem(90px) 0;

    &__entries {
        display: flex;
        flex-wrap: wrap;
        gap: rem(20.6px);
        justify-content: center;
        max-width: rem(260px);
        margin: 0 auto;

        @include tablet() {
            justify-content: flex-start;
            max-width: 820px;
            margin: 0;
        }

        @include breakpoint(1720px) {
            max-width: none;
        }
    }

    &__entry {
        text-align: center;
        background-color: #dfecbc;
        border-radius: rem(30px);
        padding: rem(28.5px);
        box-sizing: border-box;
        width: rem(256.7px);
        min-height: rem(250px);
        position: relative;

        &:after {
            left: rem(39.28px);
            top: 100%;
            border: solid transparent;
            content: '';
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-top-color: #dfecbc;
            border-width: 14.72px;

            @include tablet() {
                border-top-color: transparent;
                border-left-color: #dfecbc;
                left: 100%;
                top: rem(39.28px);
            }
        }

        &:last-child {
            &:after {
                display: none;
            }
        }

        &--active {
            background-color: var(--light-green);

            &:after {
                border-top-color: var(--light-green);

                @include tablet() {
                    border-top-color: transparent;
                    border-left-color: var(--light-green);
                }
            }
        }
    }

    &__time {
        line-height: 1.2;
        font-family: var(--gotham);
        font-size: rem(22px);
        font-weight: 400;
        margin-top: rem(8px);
        color: #006930;

        .timeline__entry--active & {
            color: white;
        }

        &:after {
            background-color: #95C11F;
            height: 3px;
            border-radius: 1.5px;
            content: '';
            margin: auto;
            max-width: rem(142px);
            margin-bottom: rem(28px);
            margin-top: rem(22px);
            display: block;

            .timeline__entry--active & {
                background-color: white;
            }
        }
    }

    &__description {
        font-size: rem(20px);
        font-family: var(--gotham);
        line-height: 1.2;
        letter-spacing: 0.1em;
        font-weight: 300;
        margin-top: rem(28px);
    }
}
